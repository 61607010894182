import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-your-email',
  templateUrl: './check-your-email.component.html',
  styleUrl: './check-your-email.component.scss',
})
export class CheckYourEmailComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => this.router.navigate(['', 'signin']), 10_000);
  }
}
