import { Action, combineReducers } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import * as fromUserTenant from './user-tenant.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
  [fromUserTenant.userTenantFeatureKey]: fromUserTenant.UserTenantState;
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromUserTenant.userTenantFeatureKey]: fromUserTenant.reducer,
    [fromAuth.authFeatureKey]: fromAuth.reducer,
  })(state, action);
}
