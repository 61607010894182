import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { selectLang } from 'src/app/auth/state/selectors/auth.selectors';

/**
 * This is intended to be used within a flow tasks but it can be used in other places too.
 * Translate a property of an object that has "it" and "en" keys based on the current language.
 * Example:
 * {
 *    "title": {
 *      "it": "Titolo",
 *      "en": "Title"
 *    }
 * }
 *
 * Usage:
 *    title | translateProperty: lang   <-- where lang is the current language (it or en)
 */

@Pipe({
  name: 'translateTranslatableProp',
})
export class TranslateTranslatablePropPipe implements PipeTransform {
  private currentLang: 'it' | 'en' | 'pt' = 'it';

  constructor(private store: Store) {
    this.store.select(selectLang).subscribe(lang => (this.currentLang = lang));
  }

  transform(obj: any, lang?: any): any {
    return get(obj, this.currentLang);
  }
}
