import { createAction, props } from '@ngrx/store';

const actionPrefix = '[Management/Tenants/Filters]';

export const resetTenantFilter = createAction(`${actionPrefix} Reset tenant filters`);

export const updateStateFilter = createAction(
  `${actionPrefix} Set tenant state filter`,
  props<{ enabled: boolean; disabled: boolean }>()
);

export const updateTextSearchFilter = createAction(
  `${actionPrefix} Set text search filter`,
  props<{ value: string }>()
);
