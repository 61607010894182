import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/core/models/users';
import { Credentials } from '../../models/credentials';

export const attemptLogin = createAction('[Login] Attempt login', props<{ credentials: Credentials }>());

export const successfullLogin = createAction('[Login] User logged in successfully', props<{ user: User.User }>());

export const failedLogin = createAction('[Login] User failed to login', props<{ errorMessage: string }>());

export const logoutUser = createAction('[Login] Logout current user');

export const logoutUserWithoutRedirecting = createAction('[Auth] Logout current user without redirecting');

export const clearMessage = createAction('[Auth] Clear http response message from the store');
