/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoleBasedNavService } from '@core/services/role-based-nav.service';
import { Store } from '@ngrx/store';
import { ConfirmActionComponent } from '@shared/components/confirm-action/confirm-action.component';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, map, tap } from 'rxjs';
import { logoutUser } from 'src/app/auth/state/actions/auth.actions';
import { selectUserId, selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../../models/users';
import { selectViewportState } from '../../store/layout/reducer';

type RoleBasedRouteOrder = {
  [key in User.UserRole]?: number;
};

export interface RouteWithPermission {
  name: string;
  path: string[];
  visibleTo: User.UserRole[];
  order?: RoleBasedRouteOrder;
  label?: string;
  iconName?: string;
  dropdown?: boolean;
}

@Component({
  selector: 'app-nav',
  styleUrls: ['./nav.component.scss'],
  template: `
    @if (loggedIn$ | async; as loggedIn) {
      <header class="pt-0" [class.flex-col]="align === 'vertical'">
        @if (isMobileView$ | async; as view) {
          <nav class="d-flex flex-wrap flex-row h-full justify-content-end align-items-center">
            @for (route of menuRoutes$ | async; track route.name) {
              @if (!route.dropdown) {
                <a
                  [routerLink]="route.path"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="no-underline ms-1 mx-md-3 text-white"
                  [class.me-2]="view.isMobile"
                  style="font-weight:300"
                  (click)="view.isMobile && emitCloseNav()">
                  {{ 'NAV.' + route.name | uppercase | translate }}
                </a>
              }
            }

            <div class="d-flex align-items-end pe-0 pe-lg-2" style="order:100">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>account_circle</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                @for (route of menuRoutes$ | async; track route.name) {
                  @if (route.dropdown) {
                    <a
                      mat-menu-item
                      [routerLink]="route.path"
                      routerLinkActive="active-link"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="no-underline text-white"
                      (click)="view.isMobile && emitCloseNav()">
                      {{ 'NAV.' + route.name | uppercase | translate }}
                    </a>
                  }
                }
                <a
                  mat-menu-item
                  (click)="logout(view.isMobile)"
                  style="order:100"
                  class="no-underline text-white"
                  [class.pt-0]="view.isMobile"
                  [class.pt-12px]="!view.isMobile">
                  Logout
                </a>
              </mat-menu>
            </div>
          </nav>
        }
      </header>
    }
  `,
})
export class NavComponent implements OnDestroy {
  @Input() align: 'vertical' | 'horizontal' = 'horizontal';
  @Output() closeNav = new EventEmitter<boolean>();

  loggedIn$ = this.store.select(selectUserId).pipe(map(Boolean));

  isMobileView$ = this.store.select(selectViewportState).pipe(map((isMobile: boolean) => ({ isMobile })));

  userRoles$: Observable<User.UserRole[] | undefined> = this.store.select(selectUserRoles);

  menuRoutes$ = new BehaviorSubject<RouteWithPermission[]>([]);

  sub = new Subscription();

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private roleBasedNavSvc: RoleBasedNavService
  ) {
    const s = combineLatest([this.userRoles$, this.roleBasedNavSvc.getRouter()])
      .pipe(
        map(([roles, routes]) =>
          routes
            .filter(route => this.canViewRoute(route, roles!))
            .sort((a, b) => this.determineOrder(a, roles) - this.determineOrder(b, roles))
        )
      )
      .subscribe(sortedRoutes => this.menuRoutes$.next(sortedRoutes));

    this.sub.add(s);
  }

  emitCloseNav() {
    this.closeNav.emit(true);
  }

  canViewRoute(route: RouteWithPermission, roles: User.UserRole[]): boolean {
    return roles ? roles.some(role => route.visibleTo.includes(role)) : false;
  }

  logout(isMobile: boolean): void {
    const dialogRef = this.dialog.open(ConfirmActionComponent);
    this.sub = dialogRef
      .afterClosed()
      .pipe(
        filter(resp => !!resp),
        tap(() => this.store.dispatch(logoutUser())),
        tap(() => isMobile && this.emitCloseNav())
      )
      .subscribe();
  }

  private determineOrder(route: RouteWithPermission, role: User.UserRole[] | undefined): number {
    if (!role) {
      return 0;
    }

    const isNativa = role!.includes(User.UserRole.NATIVA);

    return isNativa ? (route.order?.[User.UserRole.NATIVA] ?? 0) : (route.order?.[User.UserRole.MANAGER] ?? 0);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
