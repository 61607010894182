import { createReducer, on } from '@ngrx/store';
import { updateUserCurrentPage } from '../actions/user.actions';

export const usersFeatureKey = 'users';

export interface State {
  pageIndex: number;
  length?: number;
  pageSize?: number;
  previousPageIndex?: number | undefined;
}

export const initialState: State = {
  pageIndex: 0,
};

export const reducer = createReducer(
  initialState,
  on(updateUserCurrentPage, (state, page) => page)
);
