import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Assert that a multiple select have at least one option checked

export function minOptionSelected(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const choices = control.value;

    return choices.length >= min ? null : { minOptionSelected: 'At least one choice must be selected!' };
  };
}
