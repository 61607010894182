import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  styles: [],
  template: `
    <footer class="bg-dark d-flex flex-column justify-content-center align-items-center py-4 w-100 text-center">
      <p class="m-0">
        <a class="text-primary" [routerLink]="['/', 'privacy-policy']">{{
          'footer.component.privacy-policy' | translate
        }}</a>
        <span class="mx-2">|</span>
        <a class="text-primary" [routerLink]="['/', 'terms-and-conditions']">{{
          'footer.component.terms-and-conditions' | translate
        }}</a>
        <span class="mx-2">|</span>
        <a
          class="link text-primary"
          href="https://www.iubenda.com/privacy-policy/29806652/cookie-policy"
          target="_blank"
          >Cookie Policy</a
        >
      </p>
    </footer>
  `,
})
export class FooterComponent {
  constructor() {}
}
