import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SKIP_ENCODE_URL } from './http-context-tokens';

@Injectable({ providedIn: 'root' })
export class AppendNoAuthChallengeInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req?.url) {
      if (req.url.indexOf('amazonaws') !== -1 && req.url.indexOf(environment.RH_BASE_URL) === -1) {
        return next.handle(req);
      }
    }

    return next.handle(
      req.clone({
        withCredentials: true,
        url: req.context.get(SKIP_ENCODE_URL) ? req.url : encodeURI(req.url),
        headers: req.headers.append('No-Auth-Challenge', 'true'),
      })
    );
  }
}
