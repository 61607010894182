import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tenant } from '@core/models/tenant';
import { PaddleService } from '@core/services/paddle.service';
import { Store } from '@ngrx/store';
import { CheckoutEventNames, Paddle, PaddleEventData, initializePaddle } from '@paddle/paddle-js';
import { EMPTY, Observable, first, from, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { selectSelectedTenant } from '../../../auth/state/selectors/user-tenant.selector';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  currentTenant!: Tenant.Tenant | null;

  paddle$!: Observable<Paddle | undefined>;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private paddleService: PaddleService
  ) {}

  ngOnInit(): void {
    this.loadData().subscribe();

    console.debug('PaymentComponent - Initializing Paddle.js');
    this.paddle$ = from(
      initializePaddle({
        token: environment.paddle.token,
        environment: environment.production ? 'production' : 'sandbox',
        eventCallback: (event: PaddleEventData) => {
          !environment.production && console.debug('Paddle event', event);
          if (event.name === CheckoutEventNames.CHECKOUT_CLOSED) {
            this.router.navigate([this.currentTenant!._id, 'subscription']);
          } else if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED && event.data) {
            this.paddleService.handleCheckoutCompleted(event.data, !environment.production);
          }
        },
      } as any)
    );

    this.paddle$.subscribe(paddle => {
      if (paddle) {
        this.route.queryParams.subscribe(params => {
          const transactionId = params['_ptxn'];
          if (transactionId) {
            paddle.Checkout.open({
              transactionId: transactionId,
              settings: {
                successUrl: environment.paddle.successUrlPayment,
              },
            });
          }
        });
      }
    });
  }

  private loadData() {
    return this.store.select(selectSelectedTenant).pipe(
      first(tenant => !!tenant),
      switchMap(tenant => {
        this.currentTenant = tenant;
        return EMPTY;
      })
    );
  }
}
