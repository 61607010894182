import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigate-back',
  templateUrl: './navigate-back.component.html',
  styleUrls: ['./navigate-back.component.scss'],
})
export class NavigateBackComponent {
  @Input() redirectTo: string[] = [];
  constructor(
    private location: Location,
    private router: Router
  ) {}

  navigateBack(): void {
    if (this.redirectTo && this.redirectTo.length > 0) {
      this.router.navigate(this.redirectTo);
    } else {
      const splitted = this.router.url.split('/').filter(path => path !== '');
      splitted.pop();
      this.router.navigate(splitted.length > 0 ? splitted : ['']);
    }
  }
}
