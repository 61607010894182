import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmData {
  title: string;
  messages: string[];
}

@Component({
  selector: 'app-operation-confirm-dialog',
  template: `
    <section>
      <h4 mat-dialog-title>
        {{ data.title || 'operation-confirm-dialog.component.vuoi-confermare-lope' | translate }}
      </h4>

      <div class="my-2 text-center">
        @for (m of data.messages; track m) {
          <p>{{ m }}</p>
        }
      </div>

      <div class="my-2 text-center">
        <strong>{{ 'operation-confirm-dialog.component.questa-azione-non-pu' | translate }}</strong>
      </div>

      <section class="d-flex justify-content-center" mat-dialog-actions>
        <button mat-flat-button mat-dialog-close>{{ 'operation-confirm-dialog.component.annulla' | translate }}</button>

        <button mat-raised-button color="warn" [mat-dialog-close]="true" class="ms-3">
          {{ 'operation-confirm-dialog.component.conferma' | translate }}
        </button>
      </section>
    </section>
  `,
})
export class OperationConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmData
  ) {}
}
