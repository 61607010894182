import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserProfileComponent } from '../core/components/user-profile/user-profile.component';
import { SharedModule } from '../shared/shared.module';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { CheckYourEmailComponent } from './components/check-your-email/check-your-email.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { PwdResetRequestComponent } from './components/pwd-reset-request/pwd-reset-request';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SigninComponent } from './components/signin/signin.component';
import { WeakPasswordDialogComponent } from './components/weak-password-dialog/weak-password-dialog.component';
import { AuthEffects } from './state/effects/auth.effects';
import { LogoutEffect } from './state/effects/logout.effect';
import { ProfileEffect } from './state/effects/profile.effects';
import { UserTenantEffects } from './state/effects/user-tenant.effects';
import * as fromAuth from './state/reducers/index';

const materialModules = [
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatDialogModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatCheckboxModule,
];

@NgModule({
  declarations: [
    SigninComponent,
    UserProfileComponent,
    WeakPasswordDialogComponent,
    ActivateAccountComponent,
    CheckYourEmailComponent,
    PwdResetRequestComponent,
    ResetPasswordComponent,
    CheckoutComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    EffectsModule.forFeature([AuthEffects, UserTenantEffects, LogoutEffect, ProfileEffect]),
    ...materialModules,
  ],
})
export class AuthModule {}
