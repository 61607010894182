import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError, exhaustMap, map, of, tap } from 'rxjs';
import { logoutUser, successfullLogin } from 'src/app/auth/state/actions/auth.actions';
import { selectAuthUser } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';
import { PingService } from '../services/ping.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private store: Store,
    private pingSvc: PingService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectAuthUser).pipe(
      exhaustMap((user: User.User) => {
        if (user !== null) {
          // user is in auth store => logged
          return of(true);
        } else {
          // check if user is logged with access-tocket cookie
          // we can only check it sending a ping request
          return this.pingSvc.ping(undefined).pipe(
            // tap(user => console.log('*****', user)),successfullLogin
            tap(user => this.store.dispatch(successfullLogin({ user: user }))),
            map(() => true),
            catchError(() => {
              this.store.dispatch(logoutUser());
              return of(false);
            })
          );
        }
      })
    );
  }
}
