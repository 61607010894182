import { Component, OnInit } from '@angular/core';
import { GlobalErrorHandlerService } from '../../services/global-error-handler.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './app-error-page.component.html',
  styleUrls: ['./app-error-page.component.scss'],
})
export class AppErrorPageComponent implements OnInit {
  error: any;

  constructor(private globalErrorHandlerService: GlobalErrorHandlerService) {}

  ngOnInit(): void {
    this.error = this.globalErrorHandlerService.pop();
  }

  getStackTrace(): string {
    return JSON.stringify(this.error);
  }
}
