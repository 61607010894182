/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter, map, tap } from 'rxjs';
import { AppRoutes } from 'src/app/ROUTES';
import { selectUserId } from 'src/app/auth/state/selectors/auth.selectors';
import { selectViewportState } from 'src/app/core/store/layout/reducer';
import { UiService } from './../../services/ui.service';

@Component({
  selector: 'app-layout[appTitle]',
  styleUrls: ['./layout.component.scss'],
  template: `
    <mat-toolbar class="d-flex align-items-between px-0" style="min-height:72px">
      <a [routerLink]="['', 'signin']" class="lh-lg no-underline link-light ms-3">
        <div class="d-flex flex-column align-items-end">
          <img src="./assets/img/topnav-logo.png" alt="SPACESHIP" width="36" />
        </div>
      </a>

      <!-- Desktop navigation -->
      @if ((isMobileView$ | async) === false) {
        <app-nav class="ms-auto"></app-nav>
      } @else {
        <!-- Mobile navigation -->
        @if (loggedIn$ | async) {
          <div class="ms-auto flex-auto d-flex justify-content-end">
            <button mat-icon-button (click)="showMobileMenu = !showMobileMenu">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
        }
      }
    </mat-toolbar>
    <app-page-spinner />

    @if (showMobileMenu && (isMobileView$ | async)) {
      <app-nav (closeNav)="showMobileMenu = false" [align]="'vertical'"></app-nav>
    }

    <div class="content">
      <main id="main" [class.container-fluid]="amIOnFlow()" [class.container]="!amIOnFlow()" class="container-mh">
        <router-outlet />
      </main>

      <app-footer class="footer"></app-footer>
    </div>
  `,
})
export class LayoutComponent implements OnDestroy {
  @Input() appTitle: string = 'spaceship';

  authRoutes = Object.values(AppRoutes.Signin).map(route => '/' + route);

  /*
    Based on screen width and on current route toggle the visibility of navigation
    and toggle between desktop and mobile view
    (replacing navigation links with a hamburger menu and viceversa)
  */
  isMobileView$: Observable<boolean>;

  loggedIn$ = this.store.select(selectUserId).pipe(map(Boolean));
  isSigninRoute$: Observable<boolean>;

  private sub = new Subscription();

  showMobileMenu = false;

  constructor(
    private store: Store,
    private router: Router,
    public uiSvc: UiService
  ) {
    this.isMobileView$ = this.store.select(selectViewportState).pipe(
      tap(isMobile => {
        if (!isMobile) {
          this.showMobileMenu = false;
        }
      })
    );

    this.isSigninRoute$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      // URLS should be stored in an Enum
      map((event: NavigationEnd) => {
        const isNotFoundRoute = this.areRoutesEqual(['/not-found'], event.url);
        const isSigninRoute = this.areRoutesEqual(this.authRoutes, event.url);
        return isSigninRoute || isNotFoundRoute;
      })
    );
  }

  areRoutesEqual(source: string[], currentRoute: string): boolean {
    const routeWithoutParams = currentRoute.split('?')[0];
    return source.includes(routeWithoutParams);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  amIOnFlow(): boolean {
    return !!this.router.url.match(/.*\/flows\/.*/);
  }
}
