import { Mongo } from './mongo';
export namespace User {
  export interface User {
    _id: string;
    roles: UserRole[];
    profile: UserProfile;
    tenants: string[];
    authenticated?: boolean;
    enabled: boolean;
  }

  export interface UserProfile {
    name: string;
    surname: string;
    phone?: string;
    dateOfBirth?: Mongo.Date;
    additionalFields?: UserProfile.AdditionalField[];
    lang?: 'it' | 'en' | 'pt';
  }

  export namespace UserProfile {
    export interface AdditionalField {
      field: string;
      value: string;
    }
  }

  export enum UserRole {
    NATIVA = 'nativa',
    MANAGER = 'manager',
    USER = 'user',
    LOCKED_USER = 'locked_user',
    ADMIN = 'admin',
  }
}
