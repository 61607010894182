/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/core/models/users';
import {
  clearMessage,
  failedLogin,
  logoutUser,
  logoutUserWithoutRedirecting,
  successfullLogin,
} from '../actions/auth.actions';
import { updateProfileSuccess } from '../actions/profile-update.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: User.User | null;
  message: string | null;
}

const initialState: AuthState = {
  user: null,
  message: null,
};

export const reducer = createReducer(
  initialState,
  on(successfullLogin, (state: AuthState, { user }) => ({
    user,
    message: null,
  })),
  on(updateProfileSuccess, (state: AuthState, { profile }) => {
    const _user = { ...state.user!, profile };
    return { user: _user, message: null };
  }),
  on(failedLogin, (state: AuthState, { errorMessage }) => ({
    user: null,
    message: errorMessage,
  })),
  on(logoutUser, () => ({
    user: null,
    message: null,
  })),
  on(logoutUserWithoutRedirecting, () => ({
    user: null,
    message: null,
  })),
  on(clearMessage, (state: AuthState) => ({ user: state.user, message: null }))
);
