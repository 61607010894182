import { Mongo } from './mongo';

export namespace News {
  export interface News extends Mongo.MongoEntity {
    content: string;
    title: string;
    type: NewsType;
    status: NewsStatus;
    featured: boolean;
    posterImage: { _id: string };
    scheduledDate?: Mongo.Date;
  }

  export enum NewsStatus {
    PUBLISHED = 'PUBLISHED',
    DRAFT = 'DRAFT',
    HIDDEN = 'HIDDEN',
  }
  export enum NewsType {
    NEWS = 'NEWS',
    EVENT = 'EVENT',
  }
}
