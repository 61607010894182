import { Component, ElementRef, OnInit, SecurityContext, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { News } from '@core/models/news';
import { atcb_action } from 'add-to-calendar-button';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { mongoDateToLongDate } from 'src/app/shared/utils/shared';
import { NewsService } from './../../../../../core/services/news.service';

@Component({
  selector: 'app-news-details',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="d-flex flex-column h-100 news-container">
      @if (news$ | async; as news) {
        <div class="mt-5">
          <app-page-header [redirectTo]="redirectTo" title="{{ news.title }}"></app-page-header>
        </div>
        @if (isEvent) {
          <section>
            <div class="w-100 d-flex justify-content-center">
              <div>
                <span>{{ longDate }}</span>
                <button
                  mat-icon-button
                  [matTooltip]="'Aggiungi al calendario'"
                  color="primary"
                  (click)="addToCalendar()">
                  <mat-icon>event</mat-icon>
                </button>
              </div>
            </div>
          </section>
        }
      }
      <main class="ql-editor preview  my-4 h-100 p-0">
        <div #newsContent class=" surface-bg mh-400 text-bigger"></div>
      </main>
    </div>
  `,
  styleUrls: ['./news-details.component.scss'],
})
export class NewsDetailsComponent implements OnInit {
  news$ = new BehaviorSubject<Partial<News.News> | null>(null);
  news!: Partial<News.News>;

  tenantId!: string;

  redirectTo!: string[];

  @ViewChild('newsContent')
  newsContent!: ElementRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private newsSvc: NewsService,
    public sanitizer: DomSanitizer
  ) {}

  get isEvent() {
    return this.news.type === News.NewsType.EVENT;
  }
  get moment() {
    return moment;
  }

  get longDate() {
    return mongoDateToLongDate(this.news.scheduledDate, 'LL');
  }

  ngOnInit(): void {
    this.tenantId = this.route.snapshot.params['tenant'];
    this.redirectTo = this.tenantId
      ? [this.tenantId, 'news']
      : ['management', 'news', 'edit', this.route.snapshot.params['newsId']];

    const newsId = this.route.snapshot.paramMap.get('newsId');
    if (newsId) this.get(newsId);
  }

  get(newsId: string) {
    this.newsSvc.get(newsId).subscribe(news => {
      this.news$.next(news);
      this.news = news;
      this.newsContent.nativeElement.innerHTML = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(news.content)
      ) as any;
    });
  }

  addToCalendar() {
    const date = moment(this.news.scheduledDate?.$date);
    atcb_action({
      name: this.news.title,
      startDate: date.format('YYYY-MM-DD'),
      endDate: date.format('YYYY-MM-DD'),
      startTime: date.format('hh:mm'),
      endTime: date.format('hh:mm'),
      options: ['Google', 'iCal', 'Apple', 'MicrosoftTeams', 'Microsoft365', 'Outlook.com', 'Yahoo'],
      trigger: 'click',
      iCalFileName: '[Spaceship] ' + this.news.title,
    });
  }
}
