import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, authFeatureKey } from '../reducers';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

/*
  {selectedTenant: ..., error: "..."}
*/
export const selectCurrentTenant = createSelector(selectAuthState, state => state.tenant);

export const selectSelectedTenant = createSelector(selectCurrentTenant, state => state.selectedTenant);
