import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(text: string, maxLenght: number): string {
    return text.length >= maxLenght ? text.substring(0, maxLenght) + '...' : text;
  }
}
