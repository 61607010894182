import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
/**
 * a simple service to hold a thrown error
 */
export class GlobalErrorHandlerService {
  error: any;

  constructor() {}

  set(error: any) {
    this.error = error;
  }

  get(): any {
    return this.error;
  }

  pop(): any {
    const error = this.error;
    this.error = null;
    return error;
  }
}
