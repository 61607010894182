/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, filter, switchMap, tap } from 'rxjs';
import { setLoading } from 'src/app/loading/state/actions/loading.actions';
import { selectIsLoading } from 'src/app/loading/state/selectors/loading.selector';
import { isFormControlEmpty } from 'src/app/shared/utils/shared';
import { environment } from 'src/environments/environment';
import { attemptLogin, clearMessage } from '../../state/actions/auth.actions';
import { selectMessage } from '../../state/selectors/auth.selectors';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnDestroy {
  environment: any;

  visiblePwd = false;

  loginForm: UntypedFormGroup;

  isLoading$ = this.store.select(selectIsLoading);

  snackbarRef!: MatSnackBarRef<TextOnlySnackBar>;

  @ViewChild('form') loginFormRef!: ElementRef<HTMLFormElement>;

  sub!: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store,
    private snackbar: MatSnackBar
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, isFormControlEmpty()]],
      password: ['', [Validators.required, isFormControlEmpty()]],
    });

    const storeSub = this.store
      .select(selectMessage)
      .pipe(
        filter(msg => !!msg),
        switchMap(message => {
          this.snackbarRef = this.snackbar.open(message, 'x', {
            duration: 3000,
          });
          this.loginFormRef.nativeElement.classList.add('shake-container');
          return this.snackbarRef.afterDismissed();
        }),
        tap(() => {
          this.store.dispatch(clearMessage());
          this.loginFormRef.nativeElement.classList.remove('shake-container');
        })
      )
      .subscribe();

    this.sub = storeSub;

    this.environment = environment;
  }

  resetPassword() {
    this.router.navigate(['signin', 'reset-request']);
  }

  signin() {
    const password = this.loginForm.value.password;
    let username = this.loginForm.value.username;

    if (typeof username === 'string') {
      username = username.toLowerCase().trim();
    }

    if (this.snackbarRef) {
      this.snackbarRef.dismiss();
    }

    this.store.dispatch(
      attemptLogin({
        credentials: {
          username,
          password,
        },
      })
    );

    this.store.dispatch(setLoading());
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
