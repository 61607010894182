import { Component } from '@angular/core';

@Component({
  selector: 'app-permission-denied-dialog',
  template: `
    <section>
      <h3 class="text-center">{{ 'permission-denied-dialog.component.you-dont-have-enough' | translate }}</h3>
      <section class="d-flex justify-content-center mt-4">
        <button mat-raised-button color="accent" mat-dialog-close>Close</button>
      </section>
    </section>
  `,
  styles: [],
})
export class PermissionDeniedDialogComponent {
  constructor() {}
}
