<div class="container mt-5">
  <mat-card>
    <mat-card-header class="mb-2">
      <mat-card-title
        ><span class="text-danger">{{
          'app-error-page.component.oops-abbiamo-un-prob' | translate
        }}</span></mat-card-title
      >
    </mat-card-header>

    <mat-card-content>
      <p>{{ 'app-error-page.component.si-e-verificato-un-e' | translate }}</p>
      @if (error) {
        <div>
          <p class="text-muted">{{ 'app-error-page.component.puoi-copiare-lerrore' | translate }}</p>
          <h5>{{ 'app-error-page.component.errore' | translate }}</h5>
          @if (error.name) {
            <div>
              <p>{{ error.name }} {{ error.message }}</p>
            </div>
          }
          @if (error.status) {
            <div>
              <p>{{ error.status }} {{ error.statusText }}</p>
            </div>
          }
          @if (error.stack) {
            <div>
              <h5>Stack</h5>
              <code>{{ error.stack }}</code>
            </div>
          }
          @if (!error.stack) {
            <div>
              <h5>Error dump</h5>
              <code>{{ error | json }}</code>
            </div>
          }
        </div>
      }
    </mat-card-content>

    <mat-card-actions align="end">
      @if (error) {
        <button
          mat-icon-button
          type="button"
          matTooltip="Copia errore"
          [matTooltipPosition]="'below'"
          [cdkCopyToClipboard]="getStackTrace()"
          class="btnClipboard">
          <mat-icon>content_copy</mat-icon>
        </button>
      }

      <button mat-icon-button type="button" matTooltip="Home" [matTooltipPosition]="'below'" [routerLink]="['']">
        <mat-icon>home</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
