import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AskConfirmationDialogComponent } from './components/ask-confirmation-dialog/ask-confirmation-dialog.component';
import { TextSearchFilterComponent } from './components/coll-filters/search-filter/text-search-filter.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { DeleteButtonWithConfirmationComponent } from './components/delete-button-with-confirmation/delete-button-with-confirmation.component';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FilterByLetterComponent } from './components/filter-by-letter/filter-by-letter.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { NavigateBackComponent } from './components/navigate-back/navigate-back.component';
import { NewsCardComponent } from './components/news/components/news-card/news-card.component';
import { NewsDetailsComponent } from './components/news/components/news-details/news-details.component';
import { NewsComponent } from './components/news/news.component';
import { OperationConfirmDialogComponent } from './components/operation-confirm-dialog/operation-confirm-dialog.component';
import { PageFiltersAndCtaComponent } from './components/page-filters-and-cta/page-filters-and-cta.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { ThemedCardComponent } from './components/themed-card/themed-card.component';
import { UploadFileDialogComponent } from './components/upload-file-dialog/upload-file-dialog';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { EditUserDetailsDialogComponent } from './components/users-management/edit-user-details-dialog/edit-user-details-dialog.component';
import { InviteUserDialogComponent } from './components/users-management/users/user-invite-dialog.component';
import { UserPropsFilterDialogComponent } from './components/users-management/users/user-props-filter-dialog.component';
import { UsersManagementComponent } from './components/users-management/users/users.component';
import { MatSnackBarDirective } from './directives/mat-snackbar.directive';
import { ReadonlyDirective } from './directives/readonly.directive';
import { TranslateTranslatablePropPipe, UppercaseFirstCharPipe } from './pipes';
import { RemapRolePipe } from './pipes/remap-role.pipe';
import { RemapTaskTypePipe } from './pipes/remap-task-type.pipe';
import { RenameFlowIdPipe } from './pipes/rename-flow-id.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
@NgModule({
  declarations: [
    MatSnackBarDirective,
    NavigateBackComponent,
    PageHeaderComponent,
    ConfirmActionComponent,
    FilterByLetterComponent,
    UserDetailsComponent,
    TextSearchFilterComponent,
    AskConfirmationDialogComponent,
    UploadFileDialogComponent,
    PageSpinnerComponent,
    UppercaseFirstCharPipe,
    ImageUploaderComponent,
    DeleteButtonWithConfirmationComponent,
    ReadonlyDirective,
    UsersManagementComponent,
    EditUserDetailsDialogComponent,
    InviteUserDialogComponent,
    UserPropsFilterDialogComponent,
    PageFiltersAndCtaComponent,
    TranslateTranslatablePropPipe,
    FileUploaderComponent,
    OperationConfirmDialogComponent,
    TranslateTranslatablePropPipe,
    NewsComponent,
    NewsCardComponent,
    NewsDetailsComponent,
    ThemedCardComponent,
    RenameFlowIdPipe,
    RemapRolePipe,
    SearchableSelectComponent,
    RemapTaskTypePipe,
    SafeHTMLPipe,
    TruncatePipe,
    FileUploadButtonComponent,
  ],
  exports: [
    UppercaseFirstCharPipe,
    MatSnackBarDirective,
    PageHeaderComponent,
    ConfirmActionComponent,
    FilterByLetterComponent,
    TranslateModule,
    UserDetailsComponent,
    TextSearchFilterComponent,
    PageSpinnerComponent,
    ImageUploaderComponent,
    DeleteButtonWithConfirmationComponent,
    ReadonlyDirective,
    UsersManagementComponent,
    PageFiltersAndCtaComponent,
    TranslateTranslatablePropPipe,
    FileUploaderComponent,
    OperationConfirmDialogComponent,
    TranslateTranslatablePropPipe,
    ThemedCardComponent,
    RenameFlowIdPipe,
    SearchableSelectComponent,
    RemapTaskTypePipe,
    SafeHTMLPipe,
    TruncatePipe,
    FileUploadButtonComponent,
  ],
  imports: [
    TranslateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    RouterModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatTableModule,
    MatOptionModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    NgxMaskModule.forChild(),
  ],
  providers: [
    {
      provide: TranslateTranslatablePropPipe,
      useClass: TranslateTranslatablePropPipe,
    },
  ],
})
export class SharedModule {}
