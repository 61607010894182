import { Directive, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const DEFAULT_DURATION = 6000;

@Directive({
  selector: '[appSnackbar]',
})
export class MatSnackBarDirective {
  protected snackBar: MatSnackBar;

  constructor(protected inj: Injector) {
    this.snackBar = this.inj.get(MatSnackBar);
  }

  openSnackbar(message: string, duration?: number): void {
    this.snackBar.open(message, 'x', {
      duration: duration || DEFAULT_DURATION,
      horizontalPosition: 'center',
    });
  }
}
