import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTenantFilters from './tenant-filters.reducers';
import * as fromTenant from './tenants.reducer';
import * as fromUserFilters from './user-filters.reducers';
import * as fromUser from './users.reducer';

export const managementFeatureKey = 'management';

export interface ManagementState {
  [fromUser.usersFeatureKey]: fromUser.State;
  [fromTenantFilters.tenantFilterFeatureKey]: fromTenantFilters.State;
  [fromUserFilters.userFilterFeatureKey]: fromUserFilters.State;
  [fromTenant.tenantsFeatureKey]: fromTenant.State;
}

export function reducers(state: ManagementState | undefined, action: Action) {
  return combineReducers({
    [fromTenant.tenantsFeatureKey]: fromTenant.reducer,
    [fromUser.usersFeatureKey]: fromUser.reducer,
    [fromTenantFilters.tenantFilterFeatureKey]: fromTenantFilters.reducer,
    [fromUserFilters.userFilterFeatureKey]: fromUserFilters.reducer,
  })(state, action);
}

export const selectManagementState = createFeatureSelector<ManagementState>(managementFeatureKey);

// TENANT CURRENT PAGE

export const selectTenantCurrentPage = createSelector(selectManagementState, state => state['tenants']);

export const selectUserCurrentPage = createSelector(selectManagementState, state => state['users']);

// TENANT FILTERS

export const selectTenantFilters = createSelector(selectManagementState, state => state['tenants-filter']);

export const selectTenantFilterSearch = createSelector(selectTenantFilters, state => state.search);

export const selectTenantFiltersFilters = createSelector(selectTenantFilters, state => state.filters);

// USER FILTERS

export const selectUserFilters = createSelector(selectManagementState, state => state['user-filter']);

export const selectUserFilterSearch = createSelector(selectUserFilters, state => state.search);

export const selectUserFilterFilters = createSelector(selectUserFilters, state => state.filters);
