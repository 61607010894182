import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@core/models/task';
import { taskTypeMap } from '../utils/task-type-map';

@Pipe({
  name: 'remapTaskType',
})
export class RemapTaskTypePipe implements PipeTransform {
  transform(value: Task.TaskUnion, ...args: unknown[]): unknown {
    return value.title?.it && !/^\s*$/.test(value.title?.it)
      ? 'Task - ' + value.title.it
      : (taskTypeMap.get(value.type) ?? 'task-name-not-found');
  }
}
