import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SKIP_ENCODE_URL } from '@core/interceptors/http-context-tokens';
import { Errors } from '@core/models/errors';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { errorHandlingFunction } from 'src/app/core/models/error-handling';
import { User } from 'src/app/core/models/users';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'any',
})
export class UsersService {
  private MGMT_URL = `${environment.RH_BASE_URL}/mgmt`;
  private BASE_URL = this.MGMT_URL + '/users';

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  getPage(page: number = 1, pageSize: number, queryFilters?: any): Observable<User.User[]> {
    const URL =
      queryFilters !== ''
        ? `${this.BASE_URL}?sort={"_id":1}&pagesize=${pageSize}&page=${page}&${queryFilters}`
        : `${this.BASE_URL}?sort={"_id":1}&pagesize=${pageSize}&page=${page}`;

    return this.http.get<User.User[]>(URL, {
      context: new HttpContext().set(SKIP_ENCODE_URL, true),
    });
  }

  getNativaUsers(): Observable<User.User[]> {
    return this.http.get<User.User[]>(
      `${this.BASE_URL}?sort={"_id":1}&filter={"roles": {"$in": ["${User.UserRole.NATIVA}"]}}`
    );
  }

  getById(email: string): Observable<User.User> {
    if (email) return this.http.get<User.User>(`${this.BASE_URL}/${email}`);
    else return this.http.get<User.User>(`${this.MGMT_URL}/me`);
  }

  getUserCollectionSize(queryFilters?: string): Observable<number> {
    const params = queryFilters ? `?${queryFilters}` : '';
    return this.http
      .get<{ _size: number }>(`${this.BASE_URL}/_size${params}`, {
        context: new HttpContext().set(SKIP_ENCODE_URL, true),
      })
      .pipe(map(size => size._size));
  }

  inviteUser(user: {
    email: string;
    tenants: string[];
    role: User.UserRole;
    lang: 'it' | 'en' | 'pt';
  }): Observable<any> {
    const email = typeof user.email === 'string' ? user.email.toLowerCase().trim() : null;

    const payload = {
      _id: email,
      tenants: user.tenants,
      targetRoles: [user.role],
      profile: { lang: user.lang },
    };

    if (email) {
      return this.http.post<HttpResponse<any>>(this.BASE_URL, payload).pipe(switchMap(() => this.sendEmail(email)));
    } else {
      console.error("Expected 'email' to be non-empty string, got: ", email);
      return of(null);
    }
  }

  sendEmail(userId: string): Observable<any> {
    return this.http
      .post<HttpResponse<any>>(`${environment.RH_BASE_URL}/otps/pwdReset/${userId}`, null, {
        withCredentials: true,
        observe: 'response',
      })
      .pipe(catchError(errorHandlingFunction(this.dialog, Errors.ResetPasswordRequestErrorMessages)));
  }

  update(id: string, profile: any, roles: User.UserRole[] = []): Observable<HttpResponse<any>> {
    return this.http
      .patch<HttpResponse<any>>(`${this.BASE_URL}/${id}`, profile, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(
        switchMap(resp => {
          if (roles.includes(User.UserRole.NATIVA)) {
            return this.clearTenantsArray(id);
          }
          return of(resp);
        })
      );
  }

  private clearTenantsArray(userId: string): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(`${this.BASE_URL}/${userId}`, {
      $set: { tenants: [] },
    });
  }

  destroy(userId: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`${this.BASE_URL}/${userId}`, {
      observe: 'response',
      responseType: 'json',
    });
  }
}
