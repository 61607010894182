import { createAction, props } from '@ngrx/store';

export const updateUserFilters = createAction(
  '[Management/Users/Filters] Added filters',
  props<{
    roles: string[];
    tenants: string[];
    status: {
      enabled: boolean;
      disabled: boolean;
    };
  }>()
);

export const updateTextSearchFilter = createAction(
  '[Management/Users/Filters] Updated text search filter',
  props<{ value: string }>()
);

export const resetUserFilters = createAction('[Management/Users/Filters] Reset user filters');
