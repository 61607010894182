import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HomepageRedirectService } from '../../services/homepage-redirect.service';

@Component({
  selector: 'app-page-not-found',
  template: `
    <div class="row h-full justify-content-center flex-column">
      <div class="col-8 mx-auto">
        <h2>{{ 'page-not-found.component.questa-non-e-la-pagi' | translate }}</h2>
        <p>
          {{ 'page-not-found.component.controlla-lindirizzo' | translate }}
        </p>
        <div class="d-flex justify-content-center mt-4">
          <button (click)="navigateHome()" color="primary" mat-raised-button>Vai alla homepage</button>
        </div>
      </div>
    </div>
  `,
  styles: [':host { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center}'],
})
export class PageNotFoundComponent implements OnDestroy {
  private subscription = new Subscription();

  constructor(public redirectSvc: HomepageRedirectService) {}

  navigateHome() {
    this.subscription.add(this.redirectSvc.redirectHome().subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
