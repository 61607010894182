export const environment = {
  RH_BASE_URL: 'https://spaceship-api.nativatools.com',
  production: true,
  ENABLE_I18N: true,
  ENABLE_PADDLE: true,
  paddle: {
    successUrl: 'https://spaceship-app.nativatools.com/signin/check-your-email',
    successUrlPayment: 'https://spaceship-app.nativatools.com/',
    token: 'live_633884a497edf61ace0c81d4812',
    products: [
      {
        flowId: 'flow_intro',
        name: 'Principi di Sostenibilità',
        image_url: 'https://spaceship.nativatools.com/assets/img/smog/sostenibilita.jpg',
        prices: {
          activationMars: 0,
          moon: 0,
          mars: 0,
        },
        priceIds: {
          activationMars: null,
          moon: 'pri_01hyb91vc2f4k2mfmrmb9zmhsc',
          mars: null,
        },
        description: {
          it: "Questo Flow ti introduce ai principi di sostenibilità e al concetto di rigenerazione. Ti guida a comprendere l'incidenza dei trend esponenziali sul pianeta e sulle nostre vite. Descrive l'evoluzione dei paradigmi di business fino ai giorni nostri.",
          en: 'This flow introduces you to sustainability principles and the concept of regeneration. It guides you in the understanding of the impact of exponential trends on our planet and on our lives. It describes how business paradigms have evolved in the course of history to the present day.',
          pt: 'Este Flow apresenta a você os princípios da sustentabilidade e os conceitos de regeneração. Ele orienta você na compreensão do impacto das tendências exponenciais no nosso planeta e nas nossas vidas. Ele descreve como os paradigmas de negócios evoluíram ao longo da história até os dias atuais.',
        },
      },
      {
        flowId: 'flow_net_zero_y1',
        name: 'Road to Zero',
        image_url: 'https://spaceship.nativatools.com/assets/img/smog/net-zero.jpg',
        prices: {
          activationMars: 111000,
          moon: 49000,
          mars: 49000,
        },
        priceIds: {
          activationMars: 'pri_01hyb972n87wph81ce1za5kn22',
          moon: 'pri_01hyb9naep4kw5t69ctcd9xhm8',
          mars: 'pri_01hyb9hq769krt6t7z60g2gwek',
        },
        description: {
          it: 'Questo Flow ti guida nella comprensione e misurazione delle emissioni di gas climalteranti della tua azienda. Ti aiuterà ad individuare le azioni di miglioramento per ridurle alla fonte e compensare quelle rimanenti.',
          en: "This Flow guides you in understanding and securing your company's climate gas emissions. It will help you identify improvement actions to reduce them at the source and offset the remaining ones.",
          pt: 'Esse Flow guia você na compreensão e na segurança das emissões de gases do efeito estufa da sua empresa. Ele o ajudará a identificar ações de melhoria para reduzi-las na fonte e compensar as residuais.',
        },
      },
      {
        flowId: 'flow_mwm',
        name: 'Regeneration Strategy',
        image_url: 'https://spaceship.nativatools.com/assets/img/smog/measure.jpg',
        prices: {
          activationMars: 71000,
          moon: 49000,
          mars: 49000,
        },
        priceIds: {
          activationMars: 'pri_01hyb9ragsg9kfj1abxew4ph65',
          moon: 'pri_01hyb9xdxrkzvb1wk1k45rz3kq',
          mars: 'pri_01hyb9ta4h5m6y9p6z8m48eq7x',
        },
        description: {
          it: "Questo Flow ti guida nella misurazione della performance economica, sociale e ambientale della tua azienda attraverso lo standard del B Impact Assessment (BIA), uno strumento di analisi, gratuito e confidenziale, già adottato da oltre 250.000 aziende nel mondo e sviluppo da B Lab, l'ente non profit ha dato origine al movimento Benefit.",
          en: "This Flow guides you in measuring your company's economic, social and environmental performance through the B Impact Assessment (BIA) standard, a free and confidential online analytical tool already adopted by more than 250,000 companies and developed by B Lab, the non-profit which started up the Benefit movement.",
          pt: 'Este Flow guia você na mensuração do desempenho econômico, social e ambiental da sua empresa por meio da Avaliação de Impacto B (BIA), uma ferramenta de análise on-line, gratuita e confidencial, já adotada por mais de 250.000 empresas e desenvolvida pelo B Lab, a organização sem fins lucrativos que deu origem ao Movimento de Benefícios.',
        },
      },
      {
        flowId: 'flow_sb',
        name: 'Società Benefit',
        image_url: 'https://spaceship.nativatools.com/assets/img/smog/benefit.jpg',
        prices: {
          activationMars: 111000,
          moon: 49000,
          mars: 49000,
        },
        priceIds: {
          activationMars: 'pri_01hyba2cfcxrnkv90tz119d2s5',
          moon: 'pri_01hyba509cdfrj6196f7vxx6dm',
          mars: 'pri_01hyba0yfdrsw7gcbeze61yc14',
        },
        description: {
          it: "Questo Flow ti guida a comprendere e approfondire come la tua azienda può operare secondo un modello benefit e le tematiche chiave per l'adozione della qualifica di Società Benefit.",
          en: 'This Flow guides you to understand and explore how your company can act as a Benefit Corporation and the key issues in adopting Benefit Corporation legal status, where the local jurisdiction allows it.',
          pt: 'Este Flow guia você para entender e explorar como a sua empresa pode atuar como uma Sociedade de Benefício (Benefit Corporation) e as principais questões para adotar o status legal de Sociedade de Benefício, onde a jurisdição local permitir.',
        },
      },
    ],
  },
};
