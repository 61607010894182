import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, exhaustMap, map } from 'rxjs';
import { updateProfileSuccess } from '../actions/profile-update.actions';

@Injectable()
export class ProfileEffect {
  constructor(
    private actions: Actions,
    private translateSvc: TranslateService
  ) {}

  onLangchange$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(updateProfileSuccess),
        map((action: any) => action.profile.lang),
        exhaustMap((lang: 'it' | 'en' | 'pt') => {
          this.translateSvc.use(lang);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
}
