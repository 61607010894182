@if (isBackBtnVisible) {
  <header class="row px-0 mx-0">
    <app-navigate-back [redirectTo]="redirectTo" class="col-3"></app-navigate-back>
    <h1 class="col-6 text-center d-flex justify-content-center">{{ title }}</h1>
  </header>
} @else {
  <header class="row px-0 mx-0">
    <h1 class="mb-0 col-12 text-center">{{ title }}</h1>
  </header>
}
