import { Component } from '@angular/core';

@Component({
  selector: 'app-confirm-action',
  template: `
    <section class="d-flex flex-column">
      <h2 mat-dialog-title>{{ 'CONFIRM_ACTION_DIALOG.TITLE' | translate }}</h2>

      <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-raised-button mat-dialog-close>{{ 'CONFIRM_ACTION_DIALOG.CANCEL' | translate }}</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true">
          {{ 'CONFIRM_ACTION_DIALOG.OK' | translate }}
        </button>
      </div>
    </section>
  `,
  styles: [],
})
export class ConfirmActionComponent {
  constructor() {}
}
