import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { selectLang } from './auth/state/selectors/auth.selectors';
import { isMobile, isNotMobile } from './core/store/layout/actions';

@Component({
  selector: 'app-root',
  styles: [],
  template: `
    <!-- <app-layout [appTitle]="title"></app-layout>  -->
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Spaceship';

  destroyed = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private translateSvc: TranslateService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectLang)
      .pipe(tap(lang => this.translateSvc.use(lang)))
      .subscribe();

    // set Breakpoints.Small min-width equal to bootstrap sm breakpoint
    // default values:
    // Breakpoints.Small (min-width: 600px) and (max-width: 959.98px)
    // Breakpoints.Medium (min-width: 960px) and (max-width: 1279.98px)

    Breakpoints.Small = '(min-width: 576px) and (max-width: 767.98px)';
    Breakpoints.Medium = '(min-width: 768px) and (max-width: 1279.98px)';

    // From 960px and above switch to "desktop view"
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(
        takeUntil(this.destroyed),
        tap((value: BreakpointState) =>
          value.matches ? this.store.dispatch(isNotMobile()) : this.store.dispatch(isMobile())
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
