import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  private sidenavContentBgSrc$ = new BehaviorSubject<string | null>(null);

  constructor() {}

  getSidenavContentBgSrc(): Observable<string | null> {
    return this.sidenavContentBgSrc$.asObservable();
  }

  setSidenavContentBgSrc(src: string | null) {
    this.sidenavContentBgSrc$.next(src);
  }
}
