<div class="container">
  <div class="row mb-2">
    <div class="w-100">
      <div class="pt-5 mx-auto text-center">
        <h1 class="text-center fw-bold">SPACESHIP</h1>
      </div>
    </div>
  </div>

  <div class="text-center w-100 mb-3">
    <h3>
      {{ 'SIGNIN.SET_PASSWORD' | translate }}
    </h3>
  </div>

  <form [formGroup]="resetPasswordForm" autocomplete="on">
    <div class="row">
      <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4">
        <div class="d-flex justify-content-center mb-2 text-primary">
          <div>
            <h4>{{ email }}</h4>
          </div>
        </div>

        <mat-form-field appearance="outline" class="w-100 mt-2">
          <mat-label>
            {{ 'SIGNIN.PASSWORD' | translate }}
          </mat-label>
          <input matInput formControlName="password" [type]="!visiblePwd ? 'password' : 'text'" required />
          <mat-icon matSuffix (click)="visiblePwd = !visiblePwd" style="cursor: pointer">{{
            visiblePwd ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          @if (passwordControl.hasError('minlength')) {
            <mat-error>
              {{ 'SIGNIN.PASSWORD_TOO_SHORT' | translate }} {{ passwordControl.getError('minlength').requiredLength }}
            </mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100 mt-2">
          <mat-label>
            {{ 'SIGNIN.PASSWORD_CONFIRM' | translate }}
          </mat-label>
          <input matInput formControlName="confirmPassword" [type]="!visiblePwd ? 'password' : 'text'" required />

          <mat-icon matSuffix (click)="visiblePwd = !visiblePwd" style="cursor: pointer">
            {{ visiblePwd ? 'visibility_off' : 'visibility' }}
          </mat-icon>

          @if (confirmPasswordControl.hasError('minlength')) {
            <mat-error>
              {{ 'SIGNIN.PASSWORD_TOO_SHORT' | translate }}
              {{ confirmPasswordControl.getError('minlength').requiredLength }}
            </mat-error>
          }
        </mat-form-field>

        @if (resetPasswordForm.hasError('passwordMismatch') && arePasswordAndConfirmPasswordTouched) {
          <p class="text-warn mb-4 text-center">
            {{ 'SIGNIN.PASSWORD_MISMATCH' | translate }}
          </p>
        }
        @if (!consentGiven) {
          <mat-checkbox formControlName="termsAndConditions" class="w-100 mt-2"
            >* {{ 'signup.component.dichiaro-di-aver-let' | translate
            }}<a [routerLink]="['', 'terms-and-conditions']" target="_blank">
              {{ 'signup.component.condizioni-generali-' | translate }}</a
            >.</mat-checkbox
          >

          <mat-checkbox formControlName="unfairTermsAndConditions" class="w-100 mt-2"
            >* {{ 'signup.component.dichiaro-di-aver-let-0' | translate }}
            <a [routerLink]="['', 'terms-and-conditions']" target="_blank">
              {{ 'signup.component.condizioni-generali-' | translate }}</a
            >.</mat-checkbox
          >

          <div class="mt-3 mb-2" style="font-size: 14px">
            {{ 'signup.component.presto-il-consenso-a' | translate
            }}<a [routerLink]="['', 'privacy-policy']" target="_blank">
              {{ 'signup.component.informativa-privacy' | translate }}</a
            >
            {{ 'signup.component.per' | translate }}:
          </div>

          <mat-checkbox formControlName="serviceAndContractFulfillment" class="w-100 mt-2"
            >* {{ 'signup.component.lerogazione-delle-in' | translate }}</mat-checkbox
          >

          <mat-checkbox formControlName="strategicPlanningAndAnalytics" class="w-100 mt-2">{{
            'signup.component.la-pianificazione-di' | translate
          }}</mat-checkbox>

          <mat-checkbox formControlName="directMarketing" class="w-100 mt-2">{{
            'signup.component.le-finalita-di-marke' | translate
          }}</mat-checkbox>

          <mat-checkbox formControlName="profiling" class="w-100 mt-2">{{
            'activate-account.component.la-profilazione' | translate
          }}</mat-checkbox>
        }
      </div>
    </div>
  </form>
  <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-5 mt-3">
    <button
      mat-flat-button
      class="w-80 d-block mx-auto mb-5"
      color="accent"
      (click)="setPassword()"
      [disabled]="!resetPasswordForm.valid">
      {{ 'activate-account.component.attiva' | translate }}
    </button>
  </div>
</div>

<ng-template #weakPasswordDialog>
  <h4 class="text-center" mat-dialog-title>{{ 'SIGNIN.PASSWORD_TOO_WEAK' | translate }}.</h4>
  <p mat-dialog-content>
    {{ 'SIGNIN.PASSWORD_SUGGESTION' | translate }}
  </p>
  <div class="d-flex justify-content-center" mat-dialog-actions>
    <button mat-flat-button color="accent" mat-dialog-close>Ok</button>
  </div>
</ng-template>
