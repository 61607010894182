/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors } from '@core/models/errors';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { errorHandlingFunction } from 'src/app/core/models/error-handling';
import { setLoading, unsetLoading } from 'src/app/loading/state/actions/loading.actions';
import { MatSnackBarDirective } from 'src/app/shared/directives/mat-snackbar.directive';
import { AuthService } from '../../services/auth.service';
import { attemptLogin, clearMessage } from '../../state/actions/auth.actions';
import ResetPasswordRequestErrorMessages = Errors.ResetPasswordRequestErrorMessages;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: ['.w-80 { width: 80% }', '::ng-deep .content { background-image: none !important; }'],
})
export class ResetPasswordComponent extends MatSnackBarDirective implements OnInit, OnDestroy {
  resetPasswordForm!: UntypedFormGroup;

  visiblePwd = false;
  email: string | null = null;

  @ViewChild('weakPasswordDialog') weakPasswordDialogTpl!: TemplateRef<any>;

  private sub = new Subscription();

  constructor(
    private router: Router,
    inj: Injector,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authSrv: AuthService,
    private fb: UntypedFormBuilder,
    private readonly translateService: TranslateService,
    private store: Store
  ) {
    super(inj);
  }

  ngOnInit(): void {
    const queryParamsSub = this.route.queryParams
      .pipe(
        tap(params => {
          if (params['email'] && params['otp']) {
            this.createResetPasswordForm(params['email'], params['otp']);
          } else {
            console.log('[ResetPasswordComponent onInit]: missing required qparams', {
              email: params['email'],
              otp: params['otp'],
              username: params['username'],
            });

            this.router.navigate(['signin']);
          }
        })
      )
      .subscribe();

    this.sub.add(queryParamsSub);
  }

  get hasPasswordMissmatchError(): boolean {
    return this.resetPasswordForm.hasError('passwordMismatch');
  }

  get passwordControl(): UntypedFormControl {
    return this.resetPasswordForm.get('password') as UntypedFormControl;
  }

  get arePasswordAndConfirmPasswordTouched(): boolean {
    return this.passwordControl.touched && this.confirmPasswordControl.touched;
  }

  get confirmPasswordControl(): UntypedFormControl {
    return this.resetPasswordForm.get('confirmPassword') as UntypedFormControl;
  }

  resetPassword(): any {
    if (!this.email) {
      return;
    }

    const { password, otp } = this.resetPasswordForm.getRawValue();

    const userId = this.email.toLowerCase().trim();
    this.authSrv
      .resetPassword(userId, otp, password)
      .pipe(
        tap(() => {
          this.store.dispatch(
            attemptLogin({
              credentials: {
                username: userId,
                password,
              },
            })
          );

          this.store.dispatch(setLoading());
        }),
        catchError((error: any) => {
          this.store.dispatch(unsetLoading());

          if (error.status === 406) {
            return this.dialog
              .open(this.weakPasswordDialogTpl)
              .afterClosed()
              .pipe(switchMap(() => EMPTY));
          } else {
            return error;
          }
        }),
        catchError(errorHandlingFunction(this.dialog, ResetPasswordRequestErrorMessages)),
        finalize(() => this.store.dispatch(clearMessage()))
      )
      .subscribe();
  }

  private createResetPasswordForm(email: string, otp: string) {
    this.email = email;

    this.resetPasswordForm = this.fb.group(
      {
        otp: [{ value: otp, disabled: true }],
        password: ['', Validators.minLength(8)],
        confirmPassword: ['', Validators.minLength(8)],
      },
      {
        validators: this.equalPasswordValidatorFn,
      }
    );
  }

  private equalPasswordValidatorFn(control: AbstractControl): { [key: string]: any } | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password && confirmPassword) {
      return password.value === confirmPassword.value ? null : { passwordMismatch: true };
    }

    return null;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
