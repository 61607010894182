import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, take } from 'rxjs/operators';

import { HttpResponse } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Errors } from '@core/models/errors';
import { Subscription, interval } from 'rxjs';
import { errorHandlingFunction } from 'src/app/core/models/error-handling';
import { MatSnackBarDirective } from 'src/app/shared/directives/mat-snackbar.directive';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import ResetPasswordRequestErrorMessages = Errors.ResetPasswordRequestErrorMessages;

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pwd-reset-request',
  templateUrl: './pwd-reset-request.html',
  styleUrls: ['./pwd-reset-request.css'],
})
export class PwdResetRequestComponent extends MatSnackBarDirective implements OnInit, OnDestroy {
  userActivationSub!: Subscription;

  email!: string | null;

  environment: any;

  active = true;

  counterText!: string | null;

  subs: Subscription[] = [];

  otpCounter = 0;

  constructor(
    private authSrv: AuthService,
    protected override inj: Injector,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {
    super(inj);
    this.environment = environment;
  }

  ngOnInit(): void {
    this.email = null;

    const counterSub = interval(1000).subscribe(() => {
      this.counterText = this.getWaitText(this.otpCounter);
    });

    this.subs.push(counterSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  canRequestEmail() {
    const now = new Date().getTime();
    return this.otpCounter < now;
  }

  getWaitText(counter: number) {
    const now = new Date().getTime();
    const delta = counter - now;
    const waitTime = new Date(delta);

    const minutes = waitTime.getMinutes();
    const seconds = waitTime.getSeconds();
    let waitText = this.translateService.instant('pwd-reset-request.devi-aspettare') + ' ';
    waitText += `${minutes}:`;
    waitText += String(seconds).length > 1 ? seconds : `0${seconds}`;
    waitText += ' ' + this.translateService.instant('pwd-reset-request.prima-di-richiedere-');
    return waitText;
  }

  requestAccountActivation(): any {
    if (this.email == null) {
      return;
    }

    this.active = false;

    const email = this.email.toLowerCase().trim();

    this.userActivationSub = this.authSrv
      .requestPwdReset(email)
      .pipe(take(1), catchError(errorHandlingFunction(this.dialog, ResetPasswordRequestErrorMessages)))
      .subscribe((res: HttpResponse<any>) => {
        this.active = false;
        this.userActivationSub.unsubscribe();
        if (res && res.status === 200) {
          this.openSnackbar(this.translateService.instant('pwd-reset-request.ti-abbiamo-inviato-u'));
          this.router.navigate(['', 'signin', 'check-your-email']);
        } else {
          this.resetOtpCounter();
        }
      });
  }

  resetOtpCounter() {
    const now = new Date().getTime();
    this.otpCounter = now + 60000;
  }

  navigateBack(): void {
    this.router.navigateByUrl('/account');
  }
}
