import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { loadingFeatureKey, reducer } from './state/reducers/loading.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(loadingFeatureKey, reducer)],
})
export class LoadingModule {}
