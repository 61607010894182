<div class="container">
  <div class="row">
    <section class="py-5">
      <app-page-header [title]="'signin-activate.component.scrivi-la-tua-email-' | translate"> </app-page-header>
    </section>
  </div>

  <form autocomplete="off" (ngSubmit)="requestAccountActivation()">
    <div class="row mt-4">
      <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4">
        <mat-form-field appearance="outline" class="w-100 mt-2">
          <mat-label>Email</mat-label>
          <input matInput name="name" [(ngModel)]="email" required />
        </mat-form-field>
      </div>

      @if (canRequestEmail()) {
      <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-5 text-center">
        <button mat-flat-button class="w-80" color="accent" type="submit" [disabled]="!active">
          {{ 'signin-activate.component.conferma' | translate }}
        </button>
      </div>
      } @else {
      <div class="mb-3 mx-auto" [innerHtml]="counterText"></div>
      }
    </div>
  </form>

  <div class="text-center"></div>
</div>
