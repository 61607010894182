import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from './components/layout/footer.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavComponent } from './components/layout/nav.component';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PaymentComponent } from '@core/components/payment/payment.component';
import { TermsAndConditionsComponent } from '@core/components/terms-and-conditions/terms-and-conditions.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthModule } from '../auth/auth.module';
import { LoadingModule } from '../loading/loading.module';
import { PaddleEffects } from '../paddle/state/effects/paddle.effects';
import * as fromPaddle from '../paddle/state/reducers';
import { SharedModule } from '../shared/shared.module';
import { AppErrorPageComponent } from './components/app-error-page/app-error-page.component';
import { ApplicativeErrorDialogComponent } from './components/applicative-error-dialog/applicative-error-dialog.component';
import { FooterWrapperComponent } from './components/footer-wrapper/footer-wrapper.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PermissionDeniedDialogComponent } from './components/permission-denied-dialog/permission-denied-dialog.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { RouteAuthorizerDirective } from './directives/route-authorizer.directive';
import { AppendNoAuthChallengeInterceptor } from './interceptors/append-no-auth-challenge.interceptor';
import { GlobalRequestErrorHandlerInterceptor } from './interceptors/global-request-error-handler.interceptor';
import { NetwordActivityInterceptor } from './interceptors/network-activity.interceptor';
import { TenantFiltersEffects } from './store/management/actions/tenant-filters.effects';
import { UserFiltersEffects } from './store/management/actions/user-filters.effects';
import { ROOT_REDUCERS, metaReducers } from './store/root.reducers';

@NgModule({
  declarations: [
    LayoutComponent,
    NavComponent,
    FooterComponent,
    AppErrorPageComponent,
    RouteAuthorizerDirective,
    ApplicativeErrorDialogComponent,
    PermissionDeniedDialogComponent,
    PageNotFoundComponent,
    FooterWrapperComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    PaymentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatMenuModule,
    LayoutModule,
    AuthModule,
    MatSnackBarModule,
    MatCardModule,
    ClipboardModule,
    MatTooltipModule,
    LoadingModule,
    StoreModule.forRoot(ROOT_REDUCERS, { metaReducers }),
    StoreModule.forFeature(fromPaddle.paddleFeatureKey, fromPaddle.reducers),
    EffectsModule.forRoot([UserFiltersEffects, TenantFiltersEffects, PaddleEffects]),
    StoreDevtoolsModule.instrument({ connectInZone: true }),
    SharedModule,
    OverlayModule,
  ],
  exports: [
    LayoutComponent,
    NavComponent,
    FooterComponent,
    StoreModule,
    EffectsModule,
    StoreDevtoolsModule,
    ApplicativeErrorDialogComponent,
    PermissionDeniedDialogComponent,
    PageNotFoundComponent,
    ApplicativeErrorDialogComponent,
  ],
  providers: [
    // ORDERS OF INTERCEPTORS MATTERS
    { provide: HTTP_INTERCEPTORS, useClass: NetwordActivityInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppendNoAuthChallengeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalRequestErrorHandlerInterceptor, multi: true },
  ],
})
export class CoreModule {}
