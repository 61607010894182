import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/models/users';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'any',
})
export class PingService {
  private MGMT_URL = `${environment.RH_BASE_URL}/mgmt`;

  constructor(private http: HttpClient) {}

  // if authentication is expired or wrong, the http interception
  // will invalidate the session
  public ping(userId: string | undefined): Observable<User.User> {
    if (userId) {
      return this.http.get<User.User>(`${this.MGMT_URL}/users/${userId}`);
    } else {
      return this.http.get<User.User>(`${this.MGMT_URL}/me`);
    }
  }
}
