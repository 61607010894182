import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-applicative-error-dialog',
  styles: [],
  template: `
    @if (error) {
      <div matDialogContent>
        <div class="w-100 text-center justify-content-center">
          <mat-icon style="color: #BD1413">warning</mat-icon>
        </div>
        <div [innerHtml]="error.message | translateTranslatableProp"></div>
        <div class="d-flex w-100 mt-2 justify-content-center">
          <button type="button" mat-button color="warn" (click)="confirm()">OK</button>
        </div>
      </div>
    }
  `,
})
export class ApplicativeErrorDialogComponent {
  error!: { message: { it: string; en: string; pt: string }; redirectTo: string | null };

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: { message: string; redirectTo: string | null },
    private router: Router,
    private dialogRef: MatDialogRef<ApplicativeErrorDialogComponent>
  ) {}

  confirm() {
    if (this.error?.redirectTo != null) {
      this.router.navigate([this.error.redirectTo]);
    }

    this.dialogRef.close();
  }
}
