import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';

@Injectable({
  providedIn: 'root',
})
export class HomepageRedirectService {
  constructor(
    private router: Router,
    private store: Store
  ) {}

  redirectHome() {
    return this.store.select(selectUserRoles).pipe(
      take(1),
      tap(roles => {
        if (!roles) {
          this.router.navigate(['']);
        } else if (roles?.includes(User.UserRole.NATIVA)) {
          this.router.navigate(['management']);
        } else {
          this.router.navigate(['']);
        }
      })
    );
  }
}
