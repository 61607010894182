import { Action, combineReducers } from '@ngrx/store';
import * as fromPaddle from './paddle.reducer';

export const paddleFeatureKey = 'paddle';

export interface PaddleState {
  [fromPaddle.paddleFeatureKey]: fromPaddle.PaddleState;
}

export function reducers(state: PaddleState | undefined, action: Action) {
  return combineReducers({
    [fromPaddle.paddleFeatureKey]: fromPaddle.reducer,
  })(state, action);
}
