export namespace Errors {
  /* Password Reset Errors */
  export const ResetPasswordRequestErrorMessages: {
    [key: number]: { message: { it: string; en: string; pt: string }; redirectTo: string | null };
  } = {
    // EMAIL_ALREADY_REQUESTED
    1000: {
      message: {
        it: "Hai già richiesto la mail per il reset della password. Attendi 1 minuto prima di chiederne un'altra. Se non l'hai ricevuta controlla lo SPAM e contatta l'assistenza.",
        en: 'You have already requested the reset password email. Wait 1 minute before asking for another one. If you have not received it, check the SPAM and contact support',
        pt: 'Você já solicitou o e-mail de redefinição de senha. Aguarde 1 minuto antes de pedir outro. Se você não o recebeu, verifique o SPAM e entre em contato com o suporte."',
      },
      redirectTo: null,
    },

    // USER_EMAIL_NOT_SPECIFIED
    1001: {
      message: {
        it: "Oops, qualcosa è andato storto. Se il problema persiste, mettiti in contatto con l'assistenza.",
        en: 'Oops, something went wrong. If the problem keeps up, get in contact with the support.',
        pt: 'Ops, algo deu errado. Se o problema continuar, entre em contato com o suporte.',
      },
      redirectTo: null,
    },

    // USER_ID_NOT_A_STRING
    1002: {
      message: {
        it: "Oops, qualcosa è andato storto. Se il problema persiste, mettiti in contatto con l'assistenza.",
        en: 'Oops, something went wrong. If the problem keeps up, get in contact with the support.',
        pt: 'Ops, algo deu errado. Se o problema continuar, entre em contato com o suporte.',
      },
      redirectTo: null,
    },

    // EMAIL_SEND_ERROR
    1003: {
      message: {
        it: "Oops, qualcosa è andato storto. Se il problema persiste, mettiti in contatto con l'assistenza.",
        en: 'Oops, something went wrong. If the problem keeps up, get in contact with the support.',
        pt: 'Ops, algo deu errado. Se o problema continuar, entre em contato com o suporte.',
      },
      redirectTo: null,
    },

    // TOO_MANY_ATTEMPTS
    2000: {
      message: {
        it: 'Hai sbagliato credenziali troppe volte. Attendi 5 minuti e riprova.',
        en: 'You have tried with wrong credentials too many times. Wait 5 minutes and try again.',
        pt: 'Você tentou com credenciais erradas muitas vezes. Aguarde 5 minutos e tente novamente.',
      },
      redirectTo: null,
    },
  };
}
