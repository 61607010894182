/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
/* eslint-disable @ngrx/prefer-concat-latest-from */
/* eslint-disable @ngrx/no-dispatch-in-effects */
/* eslint-disable @ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseTenantService } from '@core/services/base-tenant.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, concatMap, finalize, map, tap, withLatestFrom } from 'rxjs';
import { Tenant } from 'src/app/core/models/tenant';
import { User } from 'src/app/core/models/users';
import { unsetLoading } from 'src/app/loading/state/actions/loading.actions';
import { clearMessage, failedLogin, successfullLogin } from '../actions/auth.actions';
import { loadUserTenantFailure, loadUserTenantSuccess } from '../actions/user-tenant.actions';
import { selectUserRoles } from '../selectors/auth.selectors';

@Injectable()
export class UserTenantEffects {
  constructor(
    private actions$: Actions,
    private tenantSvc: BaseTenantService,
    private router: Router,
    private store: Store
  ) {}

  // load user tenant
  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(successfullLogin),
      concatMap(({ user }) => {
        if (user.roles.includes(User.UserRole.NATIVA)) {
          // redirect nativa
          this.router.navigate(['management', 'tenants']);
          this.store.dispatch(unsetLoading());
          return EMPTY;
        } else {
          /*
              Multi-tenant is supported but this application uses a single tenant model.
              If tenant is disabled the user should be redirected to login with a message.
          */
          return this.tenantSvc.getById(user?.tenants[0]).pipe(
            tap((tenant: Tenant.Tenant) => {
              if (!tenant.enabled) {
                this.store.dispatch(
                  failedLogin({ errorMessage: "L'account della tua azienda non è attivo. Contatta l'assistenza" })
                );
              }
            }),
            map(tenant => loadUserTenantSuccess({ tenant })),
            catchError(async () =>
              loadUserTenantFailure({
                error:
                  "Impossibile accedere. Si è vericato un errore nel caricare i dati della tua azienda. Contatta l'assistenza.",
              })
            ),
            finalize(() => {
              this.store.dispatch(unsetLoading());
              this.store.dispatch(clearMessage());
            })
          );
        }
      })
    )
  );

  // after successfully loading user tenant redirect user to the appropriate page based on his role.
  loadedUserTenantSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserTenantSuccess),
        withLatestFrom(this.store.select(selectUserRoles)),
        tap(([tenant, roles]) => {
          const userRoles = roles as User.UserRole[];
          if (userRoles.includes(User.UserRole.USER) && userRoles.includes(User.UserRole.MANAGER)) {
            this.router.navigate(['']);
            return;
          } else {
            this.router.navigate(['management', 'tenants']);
            return;
          }
        })
      ),
    { dispatch: false }
  );

  // on error loading teanant, logout with error message
  loadUserTenantFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserTenantFailure),
        tap(error => this.store.dispatch(failedLogin({ errorMessage: error.error })))
      ),
    { dispatch: false }
  );
}
