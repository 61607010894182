import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { SKIP_GLOBAL_ERROR_HANDLER } from 'src/app/core/interceptors/http-context-tokens';
import { Mongo } from 'src/app/core/models/mongo';
import { convertToBase64 } from 'src/app/shared/utils/base64';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'any',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  public checkCredentials(id: string, pwd: string): Observable<any> {
    const URL = `${environment.RH_BASE_URL}/mgmt/users/${id}?set-auth-token-cookie&renew-auth-token`;
    const basicCredentials = convertToBase64(`${id}:${pwd}`);
    const authorizationHeader = `Basic ${basicCredentials}`;

    return this.httpClient.get<Observable<HttpResponse<any>>>(URL, {
      headers: {
        authorization: authorizationHeader,
      },
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  public validateAccount(email: string, taxIdentifier: string): Observable<any> {
    const URL = `${environment.RH_BASE_URL}/validate-account`;

    return this.httpClient.post<Observable<HttpResponse<any>>>(
      URL,
      { email, taxIdentifier },
      {
        observe: 'response',
        responseType: 'json',
        context: new HttpContext().set(SKIP_GLOBAL_ERROR_HANDLER, true),
      }
    );
  }

  /**
   * execute a request that wipes out the access_token cookie
   */
  public logout(): Observable<any> {
    const URL = `${environment.RH_BASE_URL}/mgmt/logout`;
    return this.httpClient.post(URL, null, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
      context: new HttpContext().set(SKIP_GLOBAL_ERROR_HANDLER, true),
    });
  }

  public requestPwdReset(email: string): Observable<any> {
    const URL = `${environment.RH_BASE_URL}/otps/pwdReset/${email}`;

    return this.httpClient.post<Observable<HttpResponse<any>>>(URL, null, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  private requestUserActivationFromSignup(email: string): Observable<any> {
    const URL = `${environment.RH_BASE_URL}/otps/pwdReset/${email}?ft=true&cg=true`;

    return this.httpClient.post<Observable<HttpResponse<any>>>(URL, null, {
      withCredentials: true,
      observe: 'response',
      responseType: 'json',
    });
  }

  public resetPassword(email: string, otp: string, password: string, consents?: ConsentPreferences): Observable<any> {
    const URL = `${environment.RH_BASE_URL}/mgmt/users/${email}`;

    return this.httpClient.patch<Observable<HttpResponse<any>>>(
      URL,
      {
        otp,
        password,
        consents,
      },
      {
        withCredentials: true,
        observe: 'response',
        responseType: 'json',
        context: new HttpContext().set(SKIP_GLOBAL_ERROR_HANDLER, true),
      }
    );
  }

  public signup(email: string, tenant: string, consents: ConsentPreferences): Observable<any> {
    const URL = `${environment.RH_BASE_URL}/mgmt/users`;

    return this.httpClient
      .post<Observable<HttpResponse<any>>>(
        URL,
        {
          _id: email,
          tenants: [tenant],
          consents: consents,
        },
        {
          observe: 'response',
          responseType: 'json',
          context: new HttpContext().set(SKIP_GLOBAL_ERROR_HANDLER, true),
        }
      )
      .pipe(switchMap(r => this.requestUserActivationFromSignup(email)));
  }
}

export interface ConsentPreferences {
  termsAndConditions: boolean;
  unfairTermsAndConditions: boolean;
  serviceAndContractFulfillment: boolean;
  strategicPlanningAndAnalytics: boolean;
  directMarketing: boolean;
  profiling: boolean;
  timestamp: Mongo.Date;
}
