export const UNKNOWN = 'unknown';
export const MOON = 'MOON';
export const MARS = 'MARS';

export function selectFlowLevelFromPriceId(products: Array<any>, price_id: string): string {
  // use the price_id to select the key in the priceIds object of the products array
  try {
    for (const product of products) {
      for (const key in product.priceIds) {
        if (product.priceIds.hasOwnProperty(key) && product.priceIds[key] === price_id) {
          return key === 'activationMars' ? MARS : key.toUpperCase();
        }
      }
    }
  } catch (error) {
    console.error('Error selecting flow level from price_id', error);
    return 'error';
  }
  console.error('Unknown flow level for price_id', price_id);
  return UNKNOWN; // default value
}
