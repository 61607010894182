import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsLoading } from 'src/app/loading/state/selectors/loading.selector';

@Component({
  selector: 'app-page-spinner',
  template: `
    @if (isLoading$ | async) {
      <mat-progress-bar class="w-100" mode="query"></mat-progress-bar>
    }
  `,
  styles: [
    `
      :host mat-progress-bar {
        position: absolute;
        z-index: 1000;
      }

      :host .mat-mdc-progress-bar {
        --mdc-linear-progress-track-height: 12px;
      }
    `,
  ],
})
export class PageSpinnerComponent implements OnInit {
  isLoading$!: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectIsLoading);
  }
}
