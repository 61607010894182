import { createAction, props } from '@ngrx/store';
import { Tenant } from 'src/app/core/models/tenant';

export const loadUserTenant = createAction('[User Tenant] Load user tenant');

export const loadUserTenantSuccess = createAction(
  '[User Tenant] Loaded user tenant successfully',
  props<{ tenant: Tenant.Tenant }>()
);

export const loadUserTenantFailure = createAction(
  '[User Tenant] Failed to load user tenant',
  props<{ error: string }>()
);
