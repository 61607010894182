import { Component, OnInit } from '@angular/core';
import { News } from '@core/models/news';
import { BehaviorSubject } from 'rxjs';
import { NewsService } from './../../../core/services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  news$ = new BehaviorSubject<News.News[]>([]);

  featuredNews: News.News[] = [];
  otherNews: News.News[] = [];

  news!: News.News[];

  constructor(private newsSvc: NewsService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.newsSvc.getPage().subscribe(news => {
      this.news$.next(news);
      this.news = news;

      this.featuredNews = news.filter(n => n.featured);
      this.otherNews = news.filter(n => !n.featured);
    });
  }
}
