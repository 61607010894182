import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { Subscription, first, map, tap } from 'rxjs';
import { minOptionSelected } from 'src/app/admin/management/validators/minOptionSelected';
import { selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';
import { Mongo } from 'src/app/core/models/mongo';
import { Tenant } from 'src/app/core/models/tenant';
import { User } from 'src/app/core/models/users';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-user-details[user]',
  template: `
    <form [formGroup]="userDetailsForm" class="row">
      @if (!isUserView) {
        @if (!isLockedUser()) {
          @if (!isNativaUser()) {
            <mat-form-field appearance="outline" class="col-6">
              <mat-label>Ruoli</mat-label>
              <mat-select formControlName="roles" multiple>
                @for (role of roles; track role) {
                  <mat-option
                    [disabled]="role === lockedRole"
                    [value]="role"
                    (onSelectionChange)="handleSectionChange($event)"
                    >{{ 'ROLES.' + role | uppercase | translate }}</mat-option
                  >
                }
              </mat-select>
            </mat-form-field>
          }
        } @else {
          <p class="text-warning text-center mb-4">
            {{ 'user-details.component.lutente-non-ha-compl' | translate }}
          </p>
        }

        <mat-form-field appearance="outline" class="col-6">
          <mat-label>{{ 'user-details.component.azienda' | translate }}</mat-label>
          <input matInput name="tenants" formControlName="tenants" />
        </mat-form-field>

        @if (!isNativaUser()) {
          <mat-radio-group aria-labelledby="status" formControlName="enabled" class="col-6">
            <mat-radio-button [value]="true">{{ 'user-details.component.abilitato' | translate }}</mat-radio-button>
            <mat-radio-button class="ms-2" [value]="false">{{
              'user-details.component.disabilitato' | translate
            }}</mat-radio-button>
          </mat-radio-group>
        }
      }

      <mat-form-field appearance="outline" class="col-12 mt-3">
        <mat-label>Email</mat-label>
        <input matInput name="email" formControlName="email" />
      </mat-form-field>

      <section formGroupName="profile">
        <mat-form-field appearance="outline" class="col-12 mt-3">
          <mat-label>{{ 'user-details.component.nome' | translate }}</mat-label>
          <input matInput name="name" formControlName="name" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 mt-3">
          <mat-label>{{ 'user-details.component.cognome' | translate }}</mat-label>
          <input matInput name="surname" formControlName="surname" />
        </mat-form-field>

        @if (i18nEnabled) {
          <mat-form-field appearance="outline" class="col-12 mt-3">
            <mat-label>Lingua | Language | Língua</mat-label>
            <mat-select formControlName="lang">
              <mat-option value="it"> Italiano </mat-option>
              <mat-option value="en"> English </mat-option>
              <mat-option value="pt"> Português </mat-option>
            </mat-select>
          </mat-form-field>
        }

        <mat-form-field appearance="outline" class="col-12 mt-3">
          <mat-label>{{ 'user-details.component.telefono' | translate }}</mat-label>
          <input matInput prefix="+39" mask=" 000 000 0000" name="name" formControlName="phone" />
        </mat-form-field>

        <!-- <h4 class="text-center">{{ 'user-details.component.altro' | translate }}</h4>

        <section formArrayName="additionalFields">
          @for (field of additionalFields.controls; track field; let i = $index) {
            <section [formGroup]="getFormGroupAt(i)" class="row">
              <mat-form-field appearance="outline" class="col-12 col-lg-5">
                <mat-label>{{ 'user-details.component.campo' | translate }}</mat-label>
                <input matInput name="name" formControlName="field" />
              </mat-form-field>
              <div class="col-12 col-lg-7 row flex-nowrap">
                <mat-form-field appearance="outline" class="col-11">
                  <mat-label>{{ 'user-details.component.valore' | translate }}</mat-label>
                  <input matInput name="name" formControlName="value" />
                </mat-form-field>
                <div class="col-1 align-items-center">
                  <button mat-icon-button (click)="removeField(i)" class="mt-2">
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </div>
              </div>
            </section>
          }

          <div class="d-flex justify-content-center">
            <button mat-icon-button (click)="addField()" class="text-center">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </section> -->
      </section>
    </form>
  `,
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input() user!: User.User;

  @Input() isUserView: boolean = false;

  @Input() disabledFields: (keyof User.User)[] = [];

  @Input() tenants: Tenant.Tenant[] = [];

  @Output() formValueChange = new EventEmitter<any>();

  @Output() formStateChange = new EventEmitter<boolean>();

  userDetailsForm!: UntypedFormGroup;

  _additionalFields!: UntypedFormArray;

  roles: string[] = [];

  lockedRole = User.UserRole.LOCKED_USER;

  minDate: Date;
  maxDate: Date;

  subs: Subscription = new Subscription();

  i18nEnabled = environment.ENABLE_I18N;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 90, 0, 1);
    this.maxDate = new Date(currentYear - 15, 0, 31);
  }

  ngOnInit(): void {
    const userRolesSub = this.store
      .select(selectUserRoles)
      .pipe(
        first(Boolean),
        tap((userRoles: User.UserRole[]) => {
          const roles = Object.values(User.UserRole).filter(
            r => r !== User.UserRole.LOCKED_USER && r !== User.UserRole.ADMIN
          );

          if (!userRoles.includes(User.UserRole.NATIVA)) {
            this.roles = roles.filter(r => r !== User.UserRole.NATIVA);
          } else {
            this.roles = roles;
          }
        })
      )
      .subscribe();

    this._additionalFields = this.fb.array([]);

    this.user?.profile?.additionalFields?.forEach(({ field, value }) =>
      this._additionalFields.push(
        this.fb.group({
          field: [field],
          value: [value],
        })
      )
    );

    const userDateOfBirth = this.user.profile?.dateOfBirth?.$date
      ? new Date(this.user.profile.dateOfBirth.$date)
      : null;

    this.userDetailsForm = this.fb.group({
      tenants: [this.user?.tenants, [Validators.required]],
      roles: [{ value: this.user?.roles, disabled: this.isNativaUser() }, [Validators.required, minOptionSelected(1)]],
      enabled: [this.user?.enabled],
      email: [this.user._id],
      profile: this.fb.group({
        name: [this.user.profile?.name ?? ''],
        surname: [this.user.profile?.surname ?? ''],
        dateOfBirth: [userDateOfBirth],
        phone: [this.user.profile?.phone ?? ''],
        additionalFields: this._additionalFields,
        lang: [this.user.profile?.lang ?? 'it'],
      }),
    });

    this.userDetailsForm.get('email')?.disable();

    this.userDetailsForm.get('tenants')?.disable();

    if (this.isUserView) {
      this.userDetailsForm.get('roles')?.disable();
      this.userDetailsForm.get('enabled')?.disable();
    }

    Object.values(this.disabledFields).forEach(key => {
      this.userDetailsForm.get(key)?.disable();
    });

    this.userDetailsForm.get('phone')?.markAsTouched();

    const valueSub = this.userDetailsForm.valueChanges
      .pipe(
        map((values: any) => {
          const dateOfBirth = new Mongo.Date(values.profile.dateOfBirth?.getTime());
          if (this.isUserView) {
            return { ...values.profile, dateOfBirth };
          }
          return {
            ...values,
            profile: { ...values.profile, dateOfBirth },
          };
        }),
        tap(values => {
          this.formValueChange.emit(values);
        })
      )
      .subscribe();

    const stateSub = this.userDetailsForm.statusChanges
      .pipe(
        map(state => state === 'VALID'),
        tap(state => this.formStateChange.emit(state))
      )
      .subscribe();

    this.subs.add(valueSub);
    this.subs.add(stateSub);
    this.subs.add(userRolesSub);
  }

  handleSectionChange(changes: MatOptionSelectionChange<string>) {
    const currentRoles: string[] | null = this._roles.value;
    const incomingRole: string = changes.source.value;

    if (changes.source.selected) {
      if (incomingRole === User.UserRole.NATIVA) {
        this._roles.setValue([User.UserRole.NATIVA]);
      } else if (currentRoles && currentRoles.includes(User.UserRole.NATIVA)) {
        this._roles.setValue([incomingRole, ...currentRoles.filter(role => role !== User.UserRole.NATIVA)]);
      }
    }
  }

  get _roles(): UntypedFormControl {
    return this.userDetailsForm.get('roles') as UntypedFormControl;
  }

  get additionalFields() {
    return this.userDetailsForm?.get('profile')?.get('additionalFields') as UntypedFormArray;
  }

  addField() {
    this._additionalFields.push(
      this.fb.group({
        field: [''],
        value: [''],
      })
    );
  }

  isLockedUser(): boolean {
    return this.user.roles.map(r => r.toString()).includes('locked-user');
  }

  getFormGroupAt(position: number) {
    return this.additionalFields.at(position) as UntypedFormGroup;
  }

  removeField(position: number) {
    this.additionalFields.removeAt(position);
  }

  isNativaUser(): boolean {
    return this.user.roles.length === 1 && this.user.roles[0] === User.UserRole.NATIVA;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  compareTenants(tenant1: any, tenant2: any): boolean {
    return tenant1?._id === tenant2?._id ? true : false;
  }
}
