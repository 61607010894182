import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function goToLanding() {
  location.href = '/';
}

export function periodToDate(period: string, format = 'DD/MM/YYYY HH:mm:ss') {
  const timestamp = periodToTimestamp(period);
  return moment(timestamp).format(format);
}

function periodToTimestamp(period: string) {
  moment.locale('it');
  return moment(new Date(period)).unix() * 1000;
}

export function hasAnyKey(obj: any): boolean {
  return typeof obj === 'object' && Object.keys(obj).length > 0;
}

export function isFormControlEmpty(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    return control.value.trim() === '' ? { isEmpty: { value: control.value } } : null;
  };
}

export function mongoDateToLongDate(date: any, format = 'LLL') {
  moment.locale('it');
  return date ? moment(new Date(date.$date)).format(format) : null;
}

export function mongoIdToLongDate(oid: any, locale = 'it') {
  moment.locale(locale);
  const date = dateFromObjectId(oid);
  return moment(date).format('LLL').charAt(0).toUpperCase() + moment(date).format('LLL').slice(1);
}

export function dateFromObjectId(oid: string) {
  return new Date(parseInt(oid.substring(0, 8), 16) * 1000);
}

//Sort on properties on 1rst level
export function sortByRootProperty(a: any[], prop: string, asc = false) {
  return asc
    ? a.sort((a, b) => (a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0))
    : a.sort((a, b) => (a[prop] < b[prop] ? 1 : b[prop] < a[prop] ? -1 : 0));
}

export function resolveEnumValueByString(e: any, key: string): string | null {
  return e[key] || null;
}

export function getFilterObject(filters: any): any {
  const filterObject: any = {};
  Object.keys(filters).forEach(key => {
    if (filters[key] !== null) {
      filterObject[key] = filters[key];
    }
  });
  return filterObject;
}

export function getFilterQuery(filter: any) {
  let result = '';

  const joinAnd = (inAnd: any) => {
    if (inAnd == null) return;
    let and = '';

    Object.keys(inAnd).forEach(key => {
      if (![null, ''].includes(inAnd[key])) {
        and += `filter={"${key}":"${escapeFilterValue(inAnd[key])}"}&`;
      }
    });

    return and;
  };

  const joinOr = (inOr: any) => {
    if (inOr == null) return;
    let or = '';

    Object.keys(inOr).forEach(key => {
      if (![null, ''].includes(inOr[key])) {
        or += `{"${key}":{"$regex":"^${escapeAndEncodeRegexFilterValue(inOr[key])}", "$options":"i"}},`;
      }
    });

    if (or.length != 0) {
      return `filter={"$or": [${or}]}&`;
    }
    return or;
  };

  if (filter.inAnd) {
    result += joinAnd(filter.inAnd);
  }

  if (result.length == 0) result += '&';

  if (filter.textSearch) {
    result += joinOr(filter?.textSearch);
  }

  return result.length > 1 ? result : '';
}

export function adjustHexColor(hexInput: string, percent: number) {
  let hex = hexInput;

  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  const calculatedPercent = (100 + percent) / 100;

  r = Math.round(Math.min(255, Math.max(0, r * calculatedPercent)));
  g = Math.round(Math.min(255, Math.max(0, g * calculatedPercent)));
  b = Math.round(Math.min(255, Math.max(0, b * calculatedPercent)));

  return `#${r.toString(16).toUpperCase()}${g.toString(16).toUpperCase()}${b.toString(16).toUpperCase()}`;
}

/* String utils */

declare global {
  interface String {
    withEllipsis(maxLength: number): string;
  }
}

String.prototype.withEllipsis = function (maxLength?: number) {
  const value = String(this);
  const size = maxLength || 300;
  if (value.length > size) {
    return value.substring(0, size) + '...';
  }
  return value;
};

export async function readCsvBlob(csv: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(csv);
  });
}

const VERY_UNCOMMON_PATTERN = '-very-uncommon-pattern-20934862';

/**
 * this escapes and encodes regex qparams for RESTHeart
 * note: using this requires the request to be executed with { context: new HttpContext().set(SKIP_ENCODE_URL, true) }
 *
 * handles many cases, inclusing common issues with $regex escaping
 *
 * @param filterValue
 * @returns the value of the qparams, escaped and encoded
 */
export function escapeAndEncodeRegexFilterValue(filterValue: string): string {
  return filterValue
    ? encodeURIComponent(
        filterValue
          .replace(/\\/g, VERY_UNCOMMON_PATTERN) //              \ --> VERY_UNCOMMON_PATTERN (restheart and java wants four backslash to escape \)
          .replace(/\"/g, '\\"') //                             " --> \" (on next replace) --> \\\"
          .replace(/[.*+?^${}()|[\]\\]/g, '\\\\$&') //           special-char -> \\special-char
          .replace(`${VERY_UNCOMMON_PATTERN}`, '\\\\\\\\')
      ) //   VERY_UNCOMMON_PATTERN --> \\\\
    : filterValue;
}

/**
 * this escapes qparams for RESTHeart
 *
 * handles many cases, inclusing common issues with $regex escaping
 *
 * @param filterValue
 * @returns the value of the qparams, escaped and encoded
 */
export function escapeFilterValue(filterValue: string): string {
  return filterValue
    ? filterValue
        .replace(/\+/g, '%2B') //                               + --> %2B
        .replace(/[.*"+?^${}()|[\]\\]/g, '\\$&') //             special-char -> \special-char
    : filterValue;
}

/**
 * this escapes qparams for RESTHeart
 *
 * handles many cases, such as string containing ', + and "
 * does not escape $regex special chars, such as * and .
 *
 * @param filterValue
 * @returns the value of the qparams, escaped and encoded
 */
export function escapeFilterValueNoRegex(filterValue: string): string {
  return filterValue
    ? filterValue
        .replace(/\+/g, '%2B') //                               + --> %2B
        .replace(/["+?^${}()|[\]\\]/g, '\\$&') //             special-char -> \special-char
    : filterValue;
}

export function requiresChecked(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value ? null : { requiresChecked: control.value };
  };
}
