<div class="container mb-2">
  <div class="row mb-2">
    <div class="w-100">
      <div class="pt-4 mx-auto text-center">
        <h1 class="text-center fw-bold">SPACESHIP</h1>
      </div>
    </div>
  </div>
  <div class="w-100 mb-3">
    <div class="text-center w-100 mb-3">
      <h3 class="text-bold">
        {{ 'checkout.component.inserisci-i-dati-di-' | translate }}
      </h3>
      <p class="text-muted">{{ 'checkout.component.nel-passo-successivo' | translate }}</p>
    </div>

    <form [formGroup]="checkoutForm" autocomplete="off">
      <div class="row">
        <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-3 col-lg-6">
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>
              {{ 'signup.component.email' | translate }}
            </mat-label>
            <input matInput formControlName="customer_email" required email />
            <mat-error>{{ 'signup.component.richiesto' | translate }} </mat-error>
            <mat-hint class="text-primary">{{ 'checkout.component.la-tua-email-qui-ric' | translate }}</mat-hint>
          </mat-form-field>

          <p class="mt-5 mt-md-4 mb-2 text-primary text-center fw-bolder">
            {{ 'checkout.component.dati-di-fatturazione' | translate }}
          </p>

          <div class="row">
            <mat-form-field appearance="outline" class="mt-2 col-lg-6">
              <mat-label>
                {{ 'signup.component.azienda' | translate }}
              </mat-label>
              <input matInput formControlName="customer_business_name" required />
              <mat-error>{{ 'signup.component.richiesto' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="mt-2 col-lg-6">
              <mat-label>
                {{ 'checkout.component.partita-iva' | translate }}
              </mat-label>
              <input
                matInput
                formControlName="customer_business_taxIdentifier"
                required
                #vatcode
                [value]="vatcode.value.toUpperCase()" />
              <mat-error>{{ 'signup.component.richiesto' | translate }}.</mat-error>
              <mat-hint class="text-primary">{{ 'checkout.component.esempio' | translate }} IT01234567890</mat-hint>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="w-100 mt-2" hidden>
            <mat-label>
              {{ 'checkout.component.nazione' | translate }}
            </mat-label>
            <input matInput formControlName="customer_address_countryCode" required />
            <mat-error>{{ 'signup.component.richiesto' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>
              {{ 'checkout.component.indirizzo' | translate }}
            </mat-label>
            <input matInput formControlName="customer_address_firstLine" required />
            <mat-error>{{ 'signup.component.richiesto' | translate }}</mat-error>
          </mat-form-field>

          <div class="row">
            <mat-form-field appearance="outline" class="mt-2 col-lg-6">
              <mat-label>
                {{ 'checkout.component.citta' | translate }}
              </mat-label>
              <input matInput formControlName="customer_address_city" required />
              <mat-error>{{ 'signup.component.richiesto' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="mt-2 col-lg-6">
              <mat-label>
                {{ 'checkout.component.codice-postale' | translate }}
              </mat-label>
              <input matInput formControlName="customer_address_postalCode" required />
              <mat-error>{{ 'signup.component.richiesto' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <mat-checkbox formControlName="termsAndConditions" class="w-100 mt-2"
            >* {{ 'signup.component.dichiaro-di-aver-let' | translate }}
            <a class="text-primary" [routerLink]="['', 'terms-and-conditions']" target="_blank">
              {{ 'signup.component.condizioni-generali-' | translate }}</a
            >.</mat-checkbox
          >

          <mat-checkbox formControlName="unfairTermsAndConditions" class="w-100 mt-2"
            >* {{ 'signup.component.dichiaro-di-aver-let-0' | translate }}
            <a class="text-primary" [routerLink]="['', 'terms-and-conditions']" target="_blank">
              {{ 'signup.component.condizioni-generali-' | translate }}</a
            >.</mat-checkbox
          >

          <div class="mt-3 mb-2" style="font-size: 14px">
            {{ 'signup.component.presto-il-consenso-a' | translate }}
            <a class="text-primary" [routerLink]="['', 'privacy-policy']" target="_blank">{{
              'signup.component.informativa-privacy' | translate
            }}</a>
            {{ 'signup.component.per' | translate }}:
          </div>

          <mat-checkbox formControlName="serviceAndContractFulfillment" class="w-100 mt-2"
            >* {{ 'signup.component.lerogazione-delle-in' | translate }}</mat-checkbox
          >

          <mat-checkbox formControlName="strategicPlanningAndAnalytics" class="w-100 mt-2">{{
            'signup.component.la-pianificazione-di' | translate
          }}</mat-checkbox>

          <mat-checkbox formControlName="directMarketing" class="w-100 mt-2">{{
            'signup.component.le-finalita-di-marke' | translate
          }}</mat-checkbox>

          <mat-checkbox formControlName="profiling" class="w-100 mt-2">{{
            'checkout.component.la-profilazione' | translate
          }}</mat-checkbox>
        </div>
      </div>
    </form>
    <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4 my-3">
      @if (paddle$ | async; as paddle) {
        <button
          class="w-100 w-lg-25"
          mat-button
          mat-raised-button
          color="primary"
          [disabled]="!checkoutForm.valid"
          (click)="checkout(paddle)">
          {{ 'checkout.component.procedi' | translate }}
        </button>
      }
    </div>
  </div>

  @if (debug) {
    <h1 class="text-muted mt-5">DEBUG</h1>

    <pre>{{ queryParams | json }}</pre>

    <pre class="mt-5">{{ checkoutOpenOptions | json }}</pre>
  }

  <ng-template #errorDialog>
    <h3 mat-dialog-title class="text-center">
      {{ 'signup.component.errore' | translate }}
    </h3>
    <p mat-dialog-content>
      {{ 'signup.component.qualcosa-e-andato-st' | translate }}
    </p>
    <div class="d-flex justify-content-center" mat-dialog-actions>
      <button mat-flat-button color="accent" mat-dialog-close>Ok</button>
    </div>
  </ng-template>

  <ng-template #emailAlreadyExists>
    <h3 mat-dialog-title class="text-center">
      {{ 'signup.component.la-tua-email-risulta' | translate }}
    </h3>
    <p mat-dialog-content>
      {{ 'signup.component.se-non-ricordi-la-pa' | translate
      }}<a [routerLink]="['', 'signin', 'reset']" mat-dialog-close>&nbsp;{{ 'signup.component.qui' | translate }}</a
      >.
    </p>
    <div class="d-flex justify-content-center" mat-dialog-actions>
      <button mat-flat-button color="accent" mat-dialog-close>Ok</button>
    </div>
  </ng-template>

  <ng-template #tenantAlreadyExists>
    <h3 mat-dialog-title class="text-center">
      {{ 'signup.component.la-tua-azienda-risul' | translate }}
    </h3>
    <p mat-dialog-content>
      {{ 'signup.component.devi-chiedere-ad-un-' | translate }}
    </p>
    <div class="d-flex justify-content-center" mat-dialog-actions>
      <button mat-flat-button color="accent" mat-dialog-close>Ok</button>
    </div>
  </ng-template>
</div>
