import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckoutEventsData } from '@paddle/paddle-js';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { selectFlowLevelFromPriceId } from '../../shared/utils/flowLevelSelector';
import { Flow } from '../models/flow';
import { ProductsRoot } from '../models/paddle';

interface Item {
  item_id: string;
  item_name: string;
  affiliation: string;
  coupon?: string;
  discount: number;
  index: number;
  item_brand: string;
  item_category: string;
  item_category2?: string;
  item_list_id?: string;
  item_list_name?: string;
  price: number;
  quantity: number;
}

interface Ecommerce {
  transaction_id: string;
  value: number;
  tax: number;
  currency: string;
  coupon?: string;
  items: Item[];
}

interface DataLayerEvent {
  event: string;
  ecommerce: Ecommerce;
}

interface Window {
  dataLayer: DataLayerEvent[];
}

@Injectable({
  providedIn: 'root',
})
export class PaddleService {
  private readonly BASE_URL = environment.RH_BASE_URL;

  constructor(private http: HttpClient) {}

  getTenantActiveSubscriptions(customerId: string): Observable<any> {
    return this.http.get<Flow.Flow[]>(`${this.BASE_URL}/paddle/subscriptions?customer_id=${customerId}&status=active`);
  }

  getProducts(): Observable<any> {
    return this.http.get<ProductsRoot>(`${this.BASE_URL}/paddle/products?include=prices&status=active`);
  }

  paymentPreviewSubscription(tenant_id: string, payload: any): Observable<any> {
    return this.http.patch<any>(`${this.BASE_URL}/paddle/tenants/${tenant_id}/preview`, payload);
  }

  updateSubscription(tenant_id: string, payload: any): Observable<any> {
    return this.http.patch<any>(`${this.BASE_URL}/paddle/tenants/${tenant_id}`, payload);
  }

  makeOneTimePayment(tenant_id: string, payload: any): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/paddle/tenants/${tenant_id}/charge`, payload);
  }

  handleCheckoutCompleted(data: CheckoutEventsData, debug = false): void {
    console.debug('PaddleService - handleCheckoutCompleted');

    const items: Item[] = [];

    data.items.forEach(item => {
      items.push({
        item_id: item.price_id,
        item_name: item.product.name,
        affiliation: 'Spaceship',
        coupon: data.discount?.code,
        discount: item.totals.discount,
        index: items.length,
        item_brand: 'Nativa',
        item_category: item.product.name,
        item_category2: selectFlowLevelFromPriceId(environment.paddle.products, item.price_id),
        price: item.totals.subtotal,
        quantity: item.quantity,
      });
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    const event: DataLayerEvent = {
      event: 'purchase',
      ecommerce: {
        transaction_id: data.transaction_id,
        value: data.totals.subtotal,
        tax: data.totals.tax,
        currency: data.currency_code,
        coupon: data.discount?.code,
        items,
      },
    };

    if (debug) {
      console.debug('PaddleService - Pushing event to dataLayer', event);
    }

    window.dataLayer.push(event);
  }
}
