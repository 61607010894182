import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Faq } from '@core/models/faq';
import { Tenant } from '@core/models/tenant';
import { User } from '@core/models/users';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfirmData,
  OperationConfirmDialogComponent,
} from '@shared/components/operation-confirm-dialog/operation-confirm-dialog.component';
import { EMPTY, Observable, switchMap, tap } from 'rxjs';
import { selectSelectedTenant } from 'src/app/auth/state/selectors/user-tenant.selector';
import { selectUserRoles } from '../auth/state/selectors/auth.selectors';
import { FaqService } from './faq.service';

@Component({
  selector: 'app-faq',
  template: `
    <div class="d-flex flex-column h-100 surface-bg">
      @if (userRoles$ | async; as userRoles) {
        <div class="h-100 p-0 overflow-x-hidden">
          @if (faqs$ | async; as faqs) {
            <section class="my-4">
              <p class="text-center">{{ 'FAQ.DESCRIPTION' | translate }}</p>
            </section>
            <mat-accordion>
              @for (faq of faqs; track faq) {
                <mat-expansion-panel class="expansion-light mb-4">
                  <mat-expansion-panel-header style="height: fit-content">
                    <mat-panel-title>
                      <div class="w-100 d-flex align-items-center">
                        <span class="faq-title">{{ faq.title | translateTranslatableProp }}</span>
                        @if (isNativa(userRoles)) {
                          <div class="ms-auto d-flex faq-actions">
                            <button [matTooltip]="'Modifica FAQ'" (click)="editFaq(faq)" mat-icon-button>
                              <mat-icon>edit</mat-icon>
                            </button>
                            <button [matTooltip]="'Elimina FAQ'" (click)="askDelete(faq)" color="warn" mat-icon-button>
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        }
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <span
                    class="d-inline-block mt-3"
                    [innerHtml]="faq.description || { it: '', en: '' } | translateTranslatableProp"></span>
                </mat-expansion-panel>
              }
            </mat-accordion>
            @if (isNativa(userRoles)) {
              <section class="w-100 my-3 d-flex justify-content-center">
                <button mat-fab (click)="addFaq()">
                  <mat-icon>add</mat-icon>
                </button>
              </section>
            }
            @if (tenant$ | async; as tenant) {
              <section class="d-flex justify-content-center my-4">
                <div class="text-center row my-2">
                  <h4 class="text-uppercase">{{ 'faq.component.inviaci-una-domanda' | translate }}</h4>
                  <p>
                    {{ 'faq.component.se-non-trovi-la-risp' | translate }}
                  </p>
                  <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Domanda</mat-label>
                      <textarea [rows]="10" [formControl]="faqRequestCtrl" matInput></textarea>
                    </mat-form-field>
                  </div>
                  <div class="d-flex justify-content-center my-4">
                    <button
                      [disabled]="!faqRequestCtrl.valid"
                      mat-raised-button
                      color="primary"
                      class="w-25"
                      (click)="sendFaqRequest(tenant)">
                      {{ 'faq.component.invia-domanda' | translate }}
                    </button>
                  </div>
                </div>
              </section>
            }
          }
        </div>
      }
    </div>

    <ng-template #addFaqTpl>
      <h2 mat-dialog-title>{{ 'faq.component.modifica-domanda' | translate }}</h2>
      <div mat-dialog-content>
        <div class="row my-2" [formGroup]="faqFormGrp">
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'faq.component.titolo-italiano' | translate }}</mat-label>
              <input formControlName="title_it" matInput />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'faq.component.titolo-inglese' | translate }}</mat-label>
              <input formControlName="title_en" matInput />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'faq.component.titolo-portoghese' | translate }}</mat-label>
              <input formControlName="title_pt" matInput />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'faq.component.descrizione-italiano' | translate }}</mat-label>
              <textarea cdkTextareaAutosize formControlName="description_it" matInput></textarea>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'faq.component.descrizione-inglese' | translate }}</mat-label>
              <textarea cdkTextareaAutosize formControlName="description_en" matInput></textarea>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'faq.component.descrizione-portoghe' | translate }}</mat-label>
              <textarea cdkTextareaAutosize formControlName="description_pt" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div mat-dialog-actions class="d-flex">
          <button
            class="me-2"
            [disabled]="!faqFormGrp.valid"
            (click)="saveFaq()"
            [mat-dialog-close]
            color="primary"
            mat-raised-button>
            Salva
          </button>
          <button [mat-dialog-close] mat-raised-button>{{ 'faq.component.chiudi' | translate }}</button>
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host main {
        margin: 0 auto;
        max-width: 1100px;
        overflow-y: auto;
      }

      ::ng-deep .mat-expansion-panel-content {
        font-size: 18px !important;
      }

      mat-expansion-panel {
        padding: 1em;
      }

      .faq-title {
        /* max-width: calc(100% - 80px); */
        font-size: 1.3rem;
        line-height: 1.3;
      }
      .faq-actions {
        max-width: 80px;
      }
    `,
  ],
})
export class FaqComponent implements OnInit {
  faqs$!: Observable<Faq[]>;

  userRoles$: Observable<User.UserRole[] | undefined> = this.store.select(selectUserRoles);

  @ViewChild('addFaqTpl') addFaqTpl!: TemplateRef<any>;

  lang: 'it' | 'en' | 'pt' = 'it';

  faqFormGrp!: UntypedFormGroup;

  tenant$: Observable<Tenant.Tenant | null> = this.store.select(selectSelectedTenant);

  faqRequestCtrl = new UntypedFormControl(null, [Validators.required]);

  constructor(
    private faqSvc: FaqService,
    private dialog: MatDialog,
    private store: Store,
    private snackbar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {
    this.faqFormGrp = this.fb.group({
      _id: [null],
      title_it: [null, [Validators.required]],
      title_en: [null, [Validators.required]],
      title_pt: [null, [Validators.required]],
      description_it: [null, [Validators.required]],
      description_en: [null, [Validators.required]],
      description_pt: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.faqs$ = this.faqSvc.get();
  }

  isNativa(userRoles: User.UserRole[]): boolean {
    return userRoles.some(role => role === User.UserRole.NATIVA);
  }

  submitRequest() {
    console.log('submitRequest');
  }

  editFaq(faq: Faq) {
    this.faqFormGrp.patchValue({
      title_it: faq.title.it,
      title_en: faq.title.en,
      title_pt: faq.title.pt,
      description_it: faq.description?.it,
      description_en: faq.description?.en,
      description_pt: faq.description?.pt,
      _id: faq._id?.$oid ?? null,
    });

    this.openFaqDialog();
  }

  deleteFaq(faq: Faq) {
    this.translate.get('faq.component.faq-eliminata').subscribe(res => {
      this.faqSvc
        .delete(faq._id?.$oid as string)
        .pipe(
          tap(() => {
            this.dialog.closeAll();
            this.snackbar.open(res, 'x', { duration: 3000 });
            this.ngOnInit();
          })
        )
        .subscribe();
    });
  }

  askDelete(faq: Faq) {
    this.translate.get(['faq.component.vuoi-davvero-elimina', 'faq.component.la-faq-sara-eliminat']).subscribe(res => {
      const dialogClosed = this.dialog
        .open(OperationConfirmDialogComponent, {
          data: {
            title: res['faq.component.vuoi-davvero-elimina'],
            messages: [res['faq.component.la-faq-sara-eliminat']],
          } as ConfirmData,
        })
        .afterClosed();

      dialogClosed
        .pipe(
          switchMap((confirmed: boolean) => {
            if (confirmed) {
              this.deleteFaq(faq);
            }
            return EMPTY;
          })
        )
        .subscribe();
    });
  }

  addFaq() {
    this.faqFormGrp.reset();
    this.openFaqDialog();
  }

  openFaqDialog() {
    this.dialog.open(this.addFaqTpl);
  }

  saveFaq() {
    const faq = {
      title: {
        it: this.faqFormGrp.get('title_it')?.value,
        en: this.faqFormGrp.get('title_en')?.value,
        pt: this.faqFormGrp.get('title_pt')?.value,
      },
      description: {
        it: this.faqFormGrp.get('description_it')?.value,
        en: this.faqFormGrp.get('description_en')?.value,
        pt: this.faqFormGrp.get('description_pt')?.value,
      },
    };

    const _id = this.faqFormGrp.get('_id')?.value;

    if (_id) {
      this.faqSvc
        .update(faq, _id)
        .pipe(tap(() => this.ngOnInit()))
        .subscribe();
    } else {
      this.faqSvc
        .save(faq)
        .pipe(tap(() => this.ngOnInit()))
        .subscribe();
    }
  }

  sendFaqRequest(tenant: Tenant.Tenant | null) {
    this.translate.get('faq.component.domanda-inviata').subscribe(res => {
      if (tenant && tenant._id) {
        const request = {
          question: this.faqRequestCtrl.value,
          tenant: { _id: tenant._id },
        };

        this.faqSvc
          .sendFaqRequest(request)
          .pipe(
            tap(() => {
              this.snackbar.open(res, 'x', { duration: 3000 });
              this.faqRequestCtrl.reset();
            })
          )
          .subscribe();
      }
    });
  }
}
