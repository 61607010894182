import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Mongo } from './../models/mongo';

import { SKIP_ENCODE_URL } from '@core/interceptors/http-context-tokens';
import { News } from '@core/models/news';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private NEWS_BASE_URL = `${environment.RH_BASE_URL}/mgmt/news`;
  private NEWS_ATTACHMENTS_BASE_URL = `${environment.RH_BASE_URL}/mgmt/news.files`;

  constructor(private http: HttpClient) {}

  getPage(page: number = 1, pageSize: number = 15, queryFilters: any = {}): Observable<News.News[]> {
    const URL =
      Object.keys(queryFilters).length != 0
        ? `${this.NEWS_BASE_URL}?pagesize=${pageSize}&page=${page}&${queryFilters}`
        : `${this.NEWS_BASE_URL}?pagesize=${pageSize}&page=${page}`;

    const projection = '&keys={content:0}';

    return this.http.get<News.News[]>(URL + projection, { context: new HttpContext().set(SKIP_ENCODE_URL, true) });
  }

  get(id: string): Observable<News.News> {
    const URL = `${this.NEWS_BASE_URL}/${id}`;
    return this.http.get<News.News>(URL);
  }

  delete(id: string): Observable<News.News> {
    const URL = `${this.NEWS_BASE_URL}/${id}`;
    return this.http.delete<News.News>(URL);
  }

  getCollectionSize(queryFilters?: string): Observable<number> {
    const params = queryFilters ? `?${queryFilters}` : '';
    return this.http
      .get<{
        _size: number;
      }>(`${this.NEWS_BASE_URL}/_size${params}`, { context: new HttpContext().set(SKIP_ENCODE_URL, true) })
      .pipe(map(size => size._size));
  }

  public create(news: Partial<News.News>): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.NEWS_BASE_URL, news, {
      observe: 'response',
      responseType: 'json',
    });
  }

  public update(news: News.News): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(`${this.NEWS_BASE_URL}/${news._id.$oid}`, news, {
      observe: 'response',
      responseType: 'json',
    });
  }

  uploadPoster(newsId: Mongo.ObjectId, file: File, options = { filename: file.name }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('properties', JSON.stringify({ ...options, newsId }));

    return this.http.put(this.NEWS_ATTACHMENTS_BASE_URL + '/poster-' + newsId.$oid, formData, {
      observe: 'events',
      reportProgress: true,
    });
  }
}
