import { createReducer, on } from '@ngrx/store';
import { setLoading, unsetLoading } from '../actions/loading.actions';

export const loadingFeatureKey = 'loading';

export interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(setLoading, () => ({ isLoading: true })),
  on(unsetLoading, () => ({ isLoading: false }))
);
