import { Injectable } from '@angular/core';
import { Tenant } from '@core/models/tenant';
import { User } from '@core/models/users';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, map, tap } from 'rxjs';
import { selectSelectedTenant } from 'src/app/auth/state/selectors/user-tenant.selector';
import { environment } from 'src/environments/environment';

type RoleBasedRouteOrder = {
  [key in User.UserRole.NATIVA | User.UserRole.MANAGER]?: number;
};

export interface RouteWithPermission {
  name: string;
  path: string[];
  visibleTo: User.UserRole[];
  order?: RoleBasedRouteOrder;
  label?: string;
  iconName?: string;
  dropdown?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RoleBasedNavService {
  enablePaddle = environment.ENABLE_PADDLE;
  private readonly initialRoutes: RouteWithPermission[] = [
    {
      name: 'WORKLIST',
      path: ['management', 'worklist'],
      visibleTo: [User.UserRole.NATIVA],
      iconName: 'event_note',
      order: {
        [User.UserRole.NATIVA]: 1,
      },
    },
    {
      name: 'NOTIFICATIONS',
      path: ['management', 'notifications'],
      visibleTo: [User.UserRole.NATIVA],
      iconName: 'notifications',
      order: {
        [User.UserRole.NATIVA]: 2,
      },
    },
    {
      name: 'TENANTS',
      path: ['management', 'tenants'],
      visibleTo: [User.UserRole.NATIVA],
      iconName: 'business_center',
      order: {
        [User.UserRole.NATIVA]: 3,
      },
    },
    {
      name: 'USERS',
      path: ['management', 'users'],
      visibleTo: [User.UserRole.NATIVA],
      iconName: 'people',
      order: {
        [User.UserRole.NATIVA]: 4,
      },
    },
    {
      name: 'NEWS',
      path: ['management', 'news'],
      visibleTo: [User.UserRole.NATIVA],
      iconName: 'newspaper',
      order: {
        [User.UserRole.NATIVA]: 5,
      },
    },
    {
      name: 'ARCHIVE',
      path: ['management', 'archive'],
      visibleTo: [User.UserRole.NATIVA],
      iconName: 'archive',
      order: {
        [User.UserRole.NATIVA]: 6,
      },
    },
    {
      name: 'FAQ',
      path: ['faq'],
      visibleTo: [User.UserRole.USER, User.UserRole.MANAGER, User.UserRole.NATIVA],
      iconName: 'help_center',
      order: {
        [User.UserRole.NATIVA]: 7,
        [User.UserRole.MANAGER]: 4,
      },
    },
    {
      name: 'PROFILE',
      path: ['/profile'],
      visibleTo: [User.UserRole.USER, User.UserRole.MANAGER, User.UserRole.NATIVA],
      order: {
        [User.UserRole.MANAGER]: 8,
        [User.UserRole.NATIVA]: 10,
      },
      iconName: 'person',
      dropdown: true,
    },
    {
      name: 'CONFIGURATIONS',
      path: ['management', 'configurations'],
      visibleTo: [User.UserRole.NATIVA],
      iconName: 'settings',
      order: {
        [User.UserRole.NATIVA]: 9,
      },
    },
  ];
  currentTenant!: any;

  private routes$ = new BehaviorSubject<RouteWithPermission[]>(this.initialRoutes);

  constructor(private store: Store) {
    this.store
      .select(selectSelectedTenant)
      .pipe(
        filter(tenant => !!tenant),
        map(tenant => {
          this.currentTenant = tenant as Tenant.Tenant;
          return tenant!._id;
        }),
        tap(_id => {
          this.routes$.next([
            ...this.initialRoutes,
            {
              name: 'TENANT',
              path: [_id, 'profile'],
              visibleTo: [User.UserRole.MANAGER],
              order: {
                [User.UserRole.MANAGER]: 6,
              },
              iconName: 'business_center',
              dropdown: true,
            },
            {
              name: 'DASHBOARD',
              path: ['/'],
              visibleTo: [User.UserRole.MANAGER, User.UserRole.USER],
              iconName: 'dashboard',
              order: {
                [User.UserRole.MANAGER]: 1,
              },
            },
            {
              name: 'FLOWS',
              path: [_id, 'flows'],
              visibleTo: [User.UserRole.MANAGER, User.UserRole.USER],
              iconName: 'route',
              order: {
                [User.UserRole.MANAGER]: 2,
              },
            },
            {
              name: 'NEWS',
              path: [_id, 'news'],
              visibleTo: [User.UserRole.MANAGER, User.UserRole.USER],
              iconName: 'newspaper',
              order: {
                [User.UserRole.MANAGER]: 5,
              },
            },
            {
              name: 'ARCHIVE',
              path: [_id, 'archive'],
              visibleTo: [User.UserRole.USER, User.UserRole.MANAGER],
              iconName: 'archive',
              order: {
                [User.UserRole.MANAGER]: 3,
              },
            },
            {
              name: 'USERS',
              path: [_id, 'users'],
              visibleTo: [User.UserRole.MANAGER],
              iconName: 'people',
              order: {
                [User.UserRole.MANAGER]: 7,
              },
              dropdown: true,
            },
          ]);
        }),
        tap(_id => {
          if (this.enablePaddle && this.currentTenant.paddle_data) {
            this.routes$.next([
              ...this.routes$.value,
              {
                name: 'SUBSCRIPTION',
                path: [_id, 'subscription'],
                visibleTo: [User.UserRole.MANAGER],
                order: {
                  [User.UserRole.MANAGER]: 7,
                },
                iconName: 'person',
                dropdown: true,
              },
            ]);
          }
        })
      )
      .subscribe();
  }

  getRouter() {
    return this.routes$.asObservable();
  }

  getInitialRoutes() {
    return this.initialRoutes.slice();
  }
}
