import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap, take } from 'rxjs';
import { selectAuthUser } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';

/* 

  If user is already logged and tries to access signin page then redirect him to the homepage

*/

@Injectable({
  providedIn: 'root',
})
export class RedirectIfAuthenticatedGuard {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectAuthUser).pipe(
      take(1),
      switchMap((user: User.User) => {
        if (user) {
          this.router.navigate(['']);
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }
}
