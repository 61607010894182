<div class="container">
  <div class="row mb-2">
    <div class="w-100">
      <div class="mx-auto text-center">
        <a class="text-decoration-none text-white" [routerLink]="['', 'signin']">
          <h1 class="text-center fw-bold pt-2">SPACESHIP</h1>
        </a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4 text-center">
      <h2 class="mb-3 text-primary">{{ 'signup.component.success' | translate }}</h2>
      <p>{{ 'check-your-email.component.devi-cliccare-sul-li' | translate }}</p>
      <p>{{ 'check-your-email.component.una-volta-verificato' | translate }}</p>
      <img src="/assets/img/nativa.png" class="img-fluid mx-auto mt-2 mb-4" width="460" />
      <p class="text-muted">{{ 'check-your-email.component.se-non-trovi-subito-' | translate }}</p>
    </div>
  </div>
</div>
