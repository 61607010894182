import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, authFeatureKey } from '../reducers';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

/*
  Output:
  {user: {...}, errorMessage: ""}
*/
export const selectAuthAuthState = createSelector(selectAuthState, state => state.auth);

export const selectAuthUser = createSelector(selectAuthState, (state: any) => state.auth.user);

// Output: ["manager"]
export const selectUserRoles = createSelector(selectAuthAuthState, state => state.user?.roles);

// Output: 'test@softinstigate.com'
export const selectUserId = createSelector(selectAuthAuthState, (state: any) => state.user?._id);

export const selectMessage = createSelector(selectAuthState, (state: any) => state.auth.message);

export const selectLang = createSelector(selectAuthAuthState, (state: any) => state.user?.profile?.lang ?? 'it');
