/* eslint-disable @ngrx/no-dispatch-in-effects */
/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
/* eslint-disable @ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, exhaustMap, finalize, map } from 'rxjs';
import { User } from 'src/app/core/models/users';
import { unsetLoading } from 'src/app/loading/state/actions/loading.actions';
import { getPaddleProducts } from '../../../paddle/state/actions/paddle.actions';
import { Credentials } from '../../models/credentials';
import { AuthService } from '../../services/auth.service';
import { attemptLogin, clearMessage, failedLogin, successfullLogin } from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authSvc: AuthService,
    private store: Store,
    private translateService: TranslateService
  ) {}

  attemptLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attemptLogin),
      map((action: any) => action.credentials),
      exhaustMap((credentials: Credentials) => {
        return this.authSvc.checkCredentials(credentials.username, credentials.password).pipe(
          map((resp: any) => {
            const user = resp.body as User.User;
            user.authenticated = true;

            this.translateService.use(user.profile.lang ?? 'it');

            this.store.dispatch(getPaddleProducts());

            return successfullLogin({ user: { ...user } });
          }),
          catchError(async err => {
            switch (err.status) {
              case 401:
                return failedLogin({ errorMessage: 'Wrong credentials' });
              case 429:
                return failedLogin({
                  errorMessage: '"Too many login attempts with your account, please try again later."',
                });
              case 0:
                return failedLogin({ errorMessage: 'You are offline' });
              default:
                return failedLogin({ errorMessage: '"Something went wrong, please try again later."' });
            }
          }),
          finalize(() => {
            this.store.dispatch(unsetLoading());
            this.store.dispatch(clearMessage());
          })
        );
      })
    )
  );
}
