/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { Tenant } from 'src/app/core/models/tenant';
import { logoutUser } from '../actions/auth.actions';
import { loadUserTenantFailure, loadUserTenantSuccess } from '../actions/user-tenant.actions';

export const userTenantFeatureKey = 'tenant';

export interface UserTenantState {
  selectedTenant: Tenant.Tenant | null;
  error: string | null;
}

const initialState: UserTenantState = {
  error: null,
  selectedTenant: null,
};

export const reducer = createReducer(
  initialState,
  on(loadUserTenantSuccess, (state: UserTenantState, { tenant }) => ({
    selectedTenant: tenant,
    error: null,
  })),
  on(loadUserTenantFailure, (state: UserTenantState, { error }) => ({
    selectedTenant: null,
    error,
  })),
  on(logoutUser, () => ({ selectedTenant: null, error: null }))
);
