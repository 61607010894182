import { Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaddleService } from '@core/services/paddle.service';
import { Store } from '@ngrx/store';
import {
  CheckoutCustomer,
  CheckoutEventNames,
  CheckoutOpenLineItem,
  CheckoutOpenOptions,
  Paddle,
  PaddleEventData,
  initializePaddle,
} from '@paddle/paddle-js';
import { EMPTY, Observable, Subscription, catchError, from, switchMap } from 'rxjs';
import { Mongo } from 'src/app/core/models/mongo';
import { unsetLoading } from 'src/app/loading/state/actions/loading.actions';
import { MatSnackBarDirective } from 'src/app/shared/directives/mat-snackbar.directive';
import { requiresChecked } from 'src/app/shared/utils/shared';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styles: ['.w-80 { width: 80% }', '::ng-deep .content { background-image: none !important; }'],
})
export class CheckoutComponent extends MatSnackBarDirective implements OnInit, OnDestroy {
  checkoutForm!: UntypedFormGroup;

  @ViewChild('errorDialog') errorDialog!: TemplateRef<any>;
  @ViewChild('emailAlreadyExists') emailAlreadyExists!: TemplateRef<any>;
  @ViewChild('tenantAlreadyExists') tenantAlreadyExists!: TemplateRef<any>;

  testQparam = {
    locale: 'it',
    intro: 1,
    mwm: 1,
    sb: 2,
    net_zero: 2,
    debug: true,
  };

  private sub = new Subscription();
  queryParams!: Params;

  constructor(
    private router: Router,
    inj: Injector,
    private fb: UntypedFormBuilder,
    private authSrv: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store,
    private paddleService: PaddleService
  ) {
    super(inj);
  }

  paddle$!: Observable<Paddle | undefined>;

  ngOnInit(): void {
    console.debug('CheckoutComponent - Initializing Paddle.js');
    this.paddle$ = from(
      initializePaddle({
        token: environment.paddle.token,
        environment: environment.production ? 'production' : 'sandbox',
        eventCallback: (event: PaddleEventData) => {
          !environment.production && console.debug('Paddle event', event);
          if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED && event.data) {
            this.paddleService.handleCheckoutCompleted(event.data, !environment.production);
          }
        },
      })
    );

    const queryParamsSub = this.route.queryParams.subscribe(params => {
      // check that we have at least one paid plans
      let paid = 0;

      params['mwm'] ? paid++ : undefined;
      params['sb'] ? paid++ : undefined;
      params['net_zero'] ? paid++ : undefined;

      if (paid === 0) {
        console.error('no paid plans');
        this.router.navigate(['', 'not-found']);
      }

      this.queryParams = params;
    });
    this.sub.add(queryParamsSub);

    const vatCodePattern =
      /^(ATU\d{8}|BE0\d{9}|BG\d{9,10}|CY\d{8}L|CZ\d{8,10}|DE\d{9}|DK\d{8}|EE\d{9}|EL\d{9}|ES[A-Z]\d{7}[A-Z]|FI\d{8}|FR[A-Z0-9]{2}\d{9}|HR\d{11}|HU\d{8}|IE\d{7}[A-Z]{1,2}|IT\d{11}|LT\d{9,12}|LU\d{8}|LV\d{11}|MT\d{8}|NL\d{9}B\d{2}|PL\d{10}|PT\d{9}|RO\d{2,10}|SE\d{12}|SI\d{8}|SK\d{10})$/;

    this.checkoutForm = this.fb.group({
      customer_business_name: ['', Validators.required],
      customer_email: ['', Validators.required],
      customer_address_countryCode: ['IT', Validators.required],
      customer_address_postalCode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(3)]],
      customer_address_city: ['', Validators.required],
      customer_address_firstLine: ['', Validators.required],
      customer_business_taxIdentifier: ['', [Validators.required, Validators.pattern(vatCodePattern)]],

      termsAndConditions: [false, requiresChecked()],
      unfairTermsAndConditions: [false, requiresChecked()],
      serviceAndContractFulfillment: [false, requiresChecked()],
      strategicPlanningAndAnalytics: false,
      directMarketing: false,
      profiling: false,
    });
  }

  get debug() {
    return !!this?.queryParams['debug'];
  }

  private locale(params: Params): string {
    return params['locale'] ?? 'it';
  }

  checkout(paddle: Paddle) {
    this.authSrv
      .validateAccount(this.checkoutForm.value.customer_email, this.checkoutForm.value.customer_business_taxIdentifier)
      .pipe(
        catchError((error: any) => {
          console.error(error);
          this.store.dispatch(unsetLoading());
          if (error.status === 409) {
            return this.dialog
              .open(this.emailAlreadyExists)
              .afterClosed()
              .pipe(switchMap(() => EMPTY));
          }

          return this.dialog
            .open(this.errorDialog)
            .afterClosed()
            .pipe(switchMap(() => EMPTY));
        })
      )
      .subscribe(() => paddle.Checkout.open(this.checkoutOpenOptions));
  }

  private items(params: Params): CheckoutOpenLineItem[] {
    const ret: CheckoutOpenLineItem[] = [];
    environment.paddle.products.forEach(product => {
      const simplifiedFlowId =
        product.flowId === 'flow_net_zero_y1' ? 'net_zero' : product.flowId.substring(5, product.flowId.length);

      if (params[simplifiedFlowId]) {
        const level = params[simplifiedFlowId]; // 1->MOON, 2->MARS
        if (level === '1') {
          if (product.priceIds) {
            ret.push({ priceId: product.priceIds?.moon, quantity: 1 });
          } else {
            console.log('missing priceId for product', params[simplifiedFlowId]);
          }
        } else if (level === '2') {
          if (product?.priceIds.activationMars) {
            ret.push({
              priceId: product.priceIds?.activationMars,
              quantity: 1,
            });
          } else {
            console.log('missing activationMars priceId for product', params[simplifiedFlowId]);
          }

          if (product?.priceIds.mars) {
            ret.push({ priceId: product.priceIds?.mars, quantity: 1 });
          } else {
            console.log('missing priceId for product', params[simplifiedFlowId]);
          }
        } else {
          console.log('wrong value for qparam', simplifiedFlowId, params[simplifiedFlowId]);
        }
      }
    });

    return ret;
  }

  private customer(): CheckoutCustomer {
    return {
      email: this.checkoutForm.value.customer_email,
      address: {
        countryCode: this.checkoutForm.value.customer_address_countryCode,
        postalCode: this.checkoutForm.value.customer_address_postalCode,
        city: this.checkoutForm.value.customer_address_city,
        firstLine: this.checkoutForm.value.customer_address_firstLine,
      },
      business: {
        name: this.checkoutForm.value.customer_business_name,
        taxIdentifier: this.checkoutForm.value.customer_business_taxIdentifier,
      },
    };
  }

  private customData(): any {
    return {
      termsAndConditions: this.checkoutForm.value.termsAndConditions,
      unfairTermsAndConditions: this.checkoutForm.value.unfairTermsAndConditions,
      serviceAndContractFulfillment: this.checkoutForm.value.serviceAndContractFulfillment,
      strategicPlanningAndAnalytics: this.checkoutForm.value.strategicPlanningAndAnalytics,
      directMarketing: this.checkoutForm.value.directMarketing,
      profiling: this.checkoutForm.value.profiling,
      timeStamp: new Mongo.Date(),
    };
  }

  get checkoutOpenOptions(): CheckoutOpenOptions {
    return {
      settings: {
        locale: this.locale(this.queryParams),
        successUrl: environment.paddle.successUrl,
        displayMode: 'overlay',
        theme: 'dark',
        allowLogout: false,
      },
      items: this.items(this.queryParams),
      customer: this.customer(),
      customData: this.customData(),
    };
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
