import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { News } from '@core/models/news';
import { dateFromObjectId } from 'src/app/shared/utils/shared';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-card',
  template: `@if (news && posterUrl) {
    <mat-card class="news-card" (click)="goToNewsDetail()">
      <div class="d-flex flex-column p-3">
        <mat-card-header>
          <h3 [attr.title]="news.title" class="card-header-title" mat-card-title>{{ news.title | truncate: 46 }}</h3>
          <p mat-card-subtitle>{{ creationDate | date: 'dd/MM/yyyy' }}</p>
        </mat-card-header>
      </div>
      <mat-card-content>
        <img
          mat-card-image
          crossorigin
          [src]="posterUrl"
          class="news-image"
          [alt]="news.title + ' image' || 'news poster image'" />
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="goToNewsDetail()">{{ 'news-card.component.visualizza' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  }`,
  styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent implements OnInit {
  @Input() news!: News.News;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  NEWS_ATTACHMENTS_URL = `${environment.RH_BASE_URL}/mgmt/news.files`;
  DEFAULT_POSTER = './assets/img/news-poster.png';

  tenantId!: string;

  posterUrl!: string;

  ngOnInit() {
    this.tenantId = this.route.snapshot.params['tenant'];
    this.getPosterSrc(this.news).then(posterUrl => {
      this.posterUrl = posterUrl;
    });
  }

  async getPosterSrc(news: News.News) {
    if (news?._id) {
      const newsPosterUrl = `${this.NEWS_ATTACHMENTS_URL}/poster-${news._id.$oid}/binary`;
      const res = await fetch(newsPosterUrl);

      if (res.ok) {
        return newsPosterUrl;
      }
    }
    return this.DEFAULT_POSTER;
  }

  get creationDate() {
    return dateFromObjectId(this.news._id.$oid);
  }

  goToNewsDetail() {
    this.router.navigate([this.tenantId, 'news', 'detail', this.news._id.$oid]);
  }
}
