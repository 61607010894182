import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, first, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { updateProfileSuccess } from 'src/app/auth/state/actions/profile-update.actions';
import { selectUserId } from 'src/app/auth/state/selectors/auth.selectors';
import { UsersService } from 'src/app/core/services/users.service';
import { User } from '../../models/users';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  currentUser$!: Observable<User.User>;

  currentUserId!: string;

  canSave = false;

  formValue!: User.UserProfile;

  msgs!: { [key: string]: string };

  constructor(
    private snackBar: MatSnackBar,
    private userSvc: UsersService,
    private store: Store,
    private authSrv: AuthService,
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const storeSub = (this.currentUser$ = this.store.select(selectUserId).pipe(
      first(id => !!id),
      tap(userId => (this.currentUserId = userId)),
      switchMap((email: string) => this.userSvc.getById(email))
    ));

    this.translate
      .get([
        'global-config-container.component.si-e-verificato-un-p',
        'user-profile.component.il-tuo-profilo-e-sta',
        'user-profile.component.devi-attendere-un-mi-0',
        'user-profile.component.ops-qualcosa-e-andat',
        'user-profile.component.ti-abbiamo-inviato-u',
        'user-profile.component.ops-qualcosa-e-andat-0',
      ])
      .subscribe(msgs => (this.msgs = msgs));
  }

  onFormStateChange(state: boolean) {
    this.canSave = state;
  }

  onFormValueChange(value: any) {
    this.formValue = value;
  }

  updateUserProfile(user: User.User) {
    this.userSvc.update(this.currentUserId, { profile: { ...this.formValue } }, user.roles).subscribe({
      next: () => {
        this.store.dispatch(updateProfileSuccess({ profile: { ...this.formValue } }));
        this.msgs &&
          this.snackBar.open(this.msgs['user-profile.component.il-tuo-profilo-e-sta'], 'x', { duration: 5000 });
      },
      error: err => {
        console.error(err),
          this.msgs &&
            this.snackbar.open(this.msgs['global-config-container.component.si-e-verificato-un-p'], 'x', {
              duration: 3000,
            });
      },
    });
  }

  pwdReset(user: User.User): any {
    const email = user._id ? user._id.toLowerCase().trim() : user._id.toLowerCase().trim();

    this.authSrv
      .requestPwdReset(email)
      .pipe(
        take(1),
        catchError(error => {
          if (error.status === 417) {
            this.msgs &&
              this.snackbar.open(this.msgs['user-profile.component.devi-attendere-un-mi-0'], 'x', { duration: 5000 });
          } else {
            this.msgs &&
              this.snackbar.open(this.msgs['user-profile.component.ops-qualcosa-e-andat'], 'x', { duration: 5000 });
          }

          return EMPTY;
        })
      )
      .subscribe((res: HttpResponse<any>) => {
        if (res && res.status === 200) {
          this.msgs &&
            this.snackbar.open(this.msgs['user-profile.component.ti-abbiamo-inviato-u'], 'x', { duration: 5000 });
        } else {
          this.msgs &&
            this.snackbar.open(this.msgs['user-profile.component.ops-qualcosa-e-andat-0'], 'x', { duration: 5000 });
        }
      });
  }
}
