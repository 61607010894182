<section>
  <div id="signin" class="container mb-sm-4 mb-md-0">
    <div class="row mb-4">
      <div class="w-100">
        <div class="pt-5 mx-auto text-center">
          <h1 class="text-center mb-5 fw-bold">SPACESHIP</h1>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-11 col-md-8 col-lg-6 col-xl-5">
        <form autocomplete="on" (ngSubmit)="signin()" [formGroup]="loginForm" #form>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>Email</mat-label>
            <input matInput name="name" formControlName="username" required />
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>Password</mat-label>
            <input
              matInput
              name="password"
              [type]="!visiblePwd ? 'password' : 'text'"
              formControlName="password"
              required />
            <mat-icon matSuffix (click)="visiblePwd = !visiblePwd" style="cursor: pointer">
              {{ visiblePwd ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </mat-form-field>

          <div class="d-flex flex-column justify-content-center align-items-center mb-3">
            <button mat-flat-button class="w-80" color="accent" type="submit" [disabled]="!loginForm.valid">
              Login
            </button>

            @if (!(isLoading$ | async)) {
              <p class="text-muted mt-3">
                {{ 'signin.component.forgot-password' | translate }}
                <a class="link" color="accent" (click)="resetPassword()">{{ 'CLICK_HERE' | translate }}</a>
              </p>
            }
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
