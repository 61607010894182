/* 
    Trovare una struttura dati adeguata per gestire le route da usare nei router e nei vari componenti
    ex: 
        AppRoutes.Sigin --> 'signin'
        AppRoutes.Signin.Activate -> 'activate' (oppure direttamente )
*/
export namespace AppRoutes {
  export namespace Signin {
    export enum Route {
      ROOT = 'signin',
    }

    export enum Childrend {
      ACTIVATE = 'activate',
    }
  }

  export namespace Profile {}

  export enum Signin {
    SIGNIN = 'signin',
    ACTIVATE = 'signin/activate',
    RESET_PASSWORD = 'signin/reset',
  }

  export enum Profile {
    PROFILE = 'profile',
  }
}
