/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { Product } from '../../../core/models/paddle';
import { setProducts } from '../actions/paddle.actions';

export const paddleFeatureKey = 'paddle';

export interface PaddleState {
  products: Product[] | null;
}

const initialState: PaddleState = {
  products: [],
};

export const reducer = createReducer(
  initialState,
  on(setProducts, (state: PaddleState, { products }) => ({
    products: products,
  }))
);
