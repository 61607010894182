import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Faq } from '@core/models/faq';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  protected BASE_URL = environment.RH_BASE_URL;

  constructor(private http: HttpClient) {}

  public get(): Observable<Faq[]> {
    return this.http.get<Faq[]>(`${this.BASE_URL}/mgmt/faq?sort={_id:1}`);
  }

  public upload(csv: string) {
    return this.http.post<HttpResponse<any>>(
      `${this.BASE_URL}/mgmt/csv?db=ship&coll=mgmt_faq&id=0&update=true&sep=--`,
      csv,
      {
        headers: {
          'Content-Type': 'text/csv',
        },
      }
    );
  }

  public update(faq: Faq, _id: string) {
    return this.http.patch<HttpResponse<any>>(`${this.BASE_URL}/mgmt/faq/${_id}`, faq);
  }

  public save(faq: Faq) {
    return this.http.post<HttpResponse<any>>(`${this.BASE_URL}/mgmt/faq/`, faq);
  }

  public delete(_id: string) {
    return this.http.delete<HttpResponse<any>>(`${this.BASE_URL}/mgmt/faq/${_id}`);
  }

  public sendFaqRequest(request: { question: string; tenant: { _id: string } }) {
    return this.http.post<HttpResponse<any>>(`${this.BASE_URL}/faqRequest`, request);
  }
}
