import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PermissionDeniedDialogComponent } from '../components/permission-denied-dialog/permission-denied-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PermissionDeniedDialogService {
  constructor(private dialog: MatDialog) {}

  displayDialog(): Observable<boolean> {
    return this.dialog.open(PermissionDeniedDialogComponent).afterClosed();
  }
}
