import { createAction, props } from '@ngrx/store';

const actionPrefix = '[Management/Tenants/List]';

export const updateTenantCurrentPage = createAction(
  `${actionPrefix} Update current page`,
  props<{
    pageIndex: number;
    pageSize: number;
    length: number;
  }>()
);
