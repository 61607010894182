<div class="container">
  <div class="row mb-5">
    <div class="w-100">
      <div class="pt-5 mx-auto text-center">
        <h1 class="text-center fw-bold pt-5">SPACESHIP</h1>
      </div>
    </div>
  </div>

  <div class="text-center w-100 mb-3">
    <h4>
      <strong>{{ 'signin-reset-password.component.imposta-una-password' | translate }}</strong>
    </h4>
  </div>

  <form [formGroup]="resetPasswordForm" autocomplete="off">
    <div class="row">
      <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4">
        <div class="d-flex justify-content-center mb-2 text-primary">
          <div>
            <h4>{{ email }}</h4>
          </div>
        </div>

        <mat-form-field appearance="outline" class="w-100 mt-2">
          <mat-label>
            {{ 'SIGNIN.PASSWORD' | translate }}
          </mat-label>
          <input
            matInput
            formControlName="password"
            [type]="!visiblePwd ? 'password' : 'text'"
            autocomplete="on"
            required />
          <mat-icon matSuffix (click)="visiblePwd = !visiblePwd" style="cursor: pointer">{{
            visiblePwd ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          @if (passwordControl.hasError('minlength')) {
            <mat-error>
              {{ 'SIGNIN.PASSWORD_TOO_SHORT' | translate }} +
              {{ passwordControl.getError('minlength').requiredLength }}
            </mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100 mt-2">
          <mat-label>
            {{ 'SIGNIN.PASSWORD_CONFIRM' | translate }}
          </mat-label>
          <input matInput formControlName="confirmPassword" [type]="!visiblePwd ? 'password' : 'text'" required />

          <mat-icon matSuffix (click)="visiblePwd = !visiblePwd" style="cursor: pointer">
            {{ visiblePwd ? 'visibility_off' : 'visibility' }}
          </mat-icon>

          @if (confirmPasswordControl.hasError('minlength')) {
            <mat-error>
              {{ 'SIGNIN.PASSWORD_TOO_SHORT' | translate }} +
              {{ confirmPasswordControl.getError('minlength').requiredLength }}
            </mat-error>
          }
        </mat-form-field>

        @if (resetPasswordForm.hasError('passwordMismatch') && arePasswordAndConfirmPasswordTouched) {
          <p class="text-warn mb-4 text-center">
            {{ 'SIGNIN.PASSWORD_MISMATCH' | translate }}
          </p>
        }
      </div>
    </div>
  </form>
  <div class="offset-1 col-10 offset-md-3 col-md-6 offset-lg-4 col-lg-4 mb-5">
    <button
      mat-flat-button
      class="w-100"
      color="accent"
      (click)="resetPassword()"
      [disabled]="!resetPasswordForm.valid">
      {{ 'CONFIRM' | translate }}
    </button>
  </div>
</div>

<ng-template #weakPasswordDialog>
  <h4 class="text-center" mat-dialog-title>{{ 'SIGNIN.PASSWORD_TOO_WEAK' | translate }}.</h4>
  <p mat-dialog-content>
    {{ 'SIGNIN.PASSWORD_SUGGESTION' | translate }}
  </p>
  <div class="d-flex justify-content-center" mat-dialog-actions>
    <button mat-flat-button color="accent" mat-dialog-close>Ok</button>
  </div>
</ng-template>
