const base64Digits = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

/* eslint-disable no-bitwise */
/**
 * Converts a string to a base-64 encoded ASCII string.
 */
export function convertToBase64(input: string): string {
  let result = '';
  const charCodes = getExpandedCharCodes(input);
  let i = 0;
  const { length } = charCodes;
  // eslint-disable-next-line one-var

  let byte1: number;
  let byte2: number;
  let byte3: number;
  let byte4: number;

  while (i < length) {
    // Convert every 6-bits in the input 3 character points
    // into a base64 digit

    byte1 = charCodes[i] >> 2;
    byte2 = ((charCodes[i] & 0b00000011) << 4) | (charCodes[i + 1] >> 4);
    byte3 = ((charCodes[i + 1] & 0b00001111) << 2) | (charCodes[i + 2] >> 6);
    byte4 = charCodes[i + 2] & 0b00111111;

    // We are out of characters in the input, set the extra
    // digits to 64 (padding character).
    if (i + 1 >= length) {
      byte3 = byte4 = 64;
    } else if (i + 2 >= length) {
      byte4 = 64;
    }

    // Write to the output
    result +=
      base64Digits.charAt(byte1) + base64Digits.charAt(byte2) + base64Digits.charAt(byte3) + base64Digits.charAt(byte4);

    i += 3;
  }

  return result;
}

function getExpandedCharCodes(input: string): number[] {
  const output: number[] = [];
  const { length } = input;

  for (let i = 0; i < length; i++) {
    const charCode = input.charCodeAt(i);

    // handel utf8
    if (charCode < 0x80) {
      output.push(charCode);
    } else if (charCode < 0x800) {
      output.push((charCode >> 6) | 0b11000000);
      output.push((charCode & 0b00111111) | 0b10000000);
    } else if (charCode < 0x10000) {
      output.push((charCode >> 12) | 0b11100000);
      output.push(((charCode >> 6) & 0b00111111) | 0b10000000);
      output.push((charCode & 0b00111111) | 0b10000000);
    } else if (charCode < 0x20000) {
      output.push((charCode >> 18) | 0b11110000);
      output.push(((charCode >> 12) & 0b00111111) | 0b10000000);
      output.push(((charCode >> 6) & 0b00111111) | 0b10000000);
      output.push((charCode & 0b00111111) | 0b10000000);
    } else {
      console.log('Unexpected code point');
    }
  }

  return output;
}

/* eslint-enable no-bitwise */
