import { createAction, props } from '@ngrx/store';

const actionPrefix = '[Management/Users/List]';

export const updateUserCurrentPage = createAction(
  `${actionPrefix} Update current page`,
  props<{
    pageIndex: number;
    pageSize: number;
    length: number;
    previousPageIndex?: number | undefined;
  }>()
);
