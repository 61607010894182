import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { resetTenantFilter, updateStateFilter, updateTextSearchFilter } from '../actions/tenant-filters.action';
import { updateTenantCurrentPage } from './tenant.actions';

@Injectable()
export class TenantFiltersEffects {
  constructor(private readonly actions: Actions) {}

  onResetTenantFiltersResetPagination$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
    this.actions.pipe(
      ofType(resetTenantFilter),
      map(() => updateTenantCurrentPage({ pageIndex: 0, pageSize: 5, length: 0 }))
    )
  );

  onUpdateTextSearchFilterResetPagination$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
    this.actions.pipe(
      ofType(updateTextSearchFilter),
      map(() => updateTenantCurrentPage({ pageIndex: 0, pageSize: 5, length: 0 }))
    )
  );

  onUpdateTenantFiltersResetPagination$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
    this.actions.pipe(
      ofType(updateStateFilter),
      map(() => updateTenantCurrentPage({ pageIndex: 0, pageSize: 5, length: 0 }))
    )
  );
}
