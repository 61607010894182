import { StepItem } from '@ui-flow/models/net-zero-conf';
import { Mongo } from './mongo';
import { Source } from './source';
import { TranslatableValue } from './utils';

export namespace Task {
  export enum TASK_TYPE {
    VIDEO = 'VIDEO',
    PRESENTATION = 'PRESENTATION',
    FILE_DOWNLOAD = 'FILE_DOWNLOAD',
    INFO = 'INFO',
    QUIZ = 'QUIZ',
    INSTRUCTION = 'INSTRUCTION',
    SYNC_WITH_NATIVA = 'SYNC_WITH_NATIVA',
    IMPACT_PROFILE = 'IMPACT_PROFILE',
    MASTER_PLAN = 'MASTER_PLAN',
    EXTERNAL_ACTIVITY = 'EXTERNAL_ACTIVITY',
    TEXTAREA_TASK = 'TEXTAREA',
    CONFIRMATION = 'CONFIRMATION',
    FILLABLE_LIST = 'FILLABLE_LIST',
    MEASURE_CO2_SCOPE_1 = 'MEASURE_CO2_SCOPE_1',
    MEASURE_CO2_SCOPE_2 = 'MEASURE_CO2_SCOPE_2',
    MEASURE_CO2_SCOPE_3 = 'MEASURE_CO2_SCOPE_3',
    EMISSIONS_CHART = 'EMISSIONS_CHART',
    IMPROVEMENT_ACTIONS = 'IMPROVEMENT_ACTIONS',
    COMPENSATIONS = 'COMPENSATIONS',
  }

  export type TaskUnion =
    | VideoTask
    | PresentationTask
    | Quiz.QuizTask
    | SyncWithNativaTask
    | InfoTask
    | FileDownloadTask
    | MasterPlan.MasterPlanTask
    | ImpactProfile.ImpactProfile
    | ExternalActivityTask
    | InstructionTask
    | TextareaTask
    | ConfirmationTask
    | FillableListTask
    | MeasureCO2Scope1DataTask
    | MeasureCO2Scope2DataTask
    | MeasureCO2Scope3DataTask
    | EmissionChartTask
    | ImprovementActionsTask
    | CompensationsTask;

  export interface Task extends Mongo.MongoEntity {
    title: TranslatableValue;
    description: TranslatableValue;
    completed: boolean;
    type: TASK_TYPE;
  }

  export interface FillableListTask extends Task {
    type: TASK_TYPE.FILLABLE_LIST;
    // Represents a task ID that will have its value sincronized with the current task
    syncValueWith?: Mongo.ObjectId;
    value: {
      items: {
        body: string;
      }[];
    } | null;
  }

  export interface PresentationTask extends Task {
    type: TASK_TYPE.PRESENTATION;
    value: {
      source: Source.ExternalSource;
      infos?: {
        message: TranslatableValue;
      }[];
    };
  }

  export interface ExternalActivityTask extends Task {
    type: TASK_TYPE.EXTERNAL_ACTIVITY;
    value: {
      source: Source.ExternalSource;
    };
  }

  /**
   * ``` { completed: true }``` by default
   */
  export interface InfoTask extends Task {
    type: TASK_TYPE.INFO;
  }

  export interface SyncWithNativaTask extends Task {
    type: TASK_TYPE.SYNC_WITH_NATIVA;
    submitted: boolean;
    enabled: boolean;
    value: {
      message: string;
    };
  }

  export interface VideoTask extends Task {
    type: TASK_TYPE.VIDEO;
    value: {
      source: Source.ExternalSource;
    };
  }

  export namespace Quiz {
    export interface QuizTask extends Task {
      type: TASK_TYPE.QUIZ;
      value: {
        type: QuizChoiceType;
      };
    }

    export type QuizUnion = SingleChoiceQuiz;

    export enum QuizChoiceType {
      MULTIPLE = 'MULTIPLE',
      SINGLE = 'SINGLE',
      OPEN = 'OPEN',
    }

    export interface SingleChoiceQuiz extends QuizTask {
      value: {
        type: QuizChoiceType.SINGLE;
        questions: {
          title: TranslatableValue;
          image?: string;
          choices: {
            title: TranslatableValue;
            description: TranslatableValue;
            index: number;
          }[];
          correctChoiceIndex: number;
        }[];
      };
    }

    // For future use cases
    export interface MultipleChoiceQuiz extends QuizTask {
      value: {
        type: QuizChoiceType.SINGLE;
        title: TranslatableValue;
        image?: string;
        description: TranslatableValue;
        answers: {
          index: number;
          title: TranslatableValue;
          description: TranslatableValue;
        }[];
        correct: { index: number }[];
      };
    }

    // For future use cases
    export interface OpenChoiceQuiz extends QuizTask {
      value: {
        type: QuizChoiceType.SINGLE;
        title: TranslatableValue;
        description: TranslatableValue;
        userAnswer: string;
      };
    }
  }

  /**
   * ``` { completed: true }``` by default
   */
  export interface InstructionTask extends Task {
    type: TASK_TYPE.INSTRUCTION;
  }

  export namespace ImpactProfile {
    /**
     * ``` { completed: true }``` by default
     */
    export interface ImpactProfile extends Task {
      type: TASK_TYPE.IMPACT_PROFILE;
      simplified: boolean;
      value: ImpactProfileValue | null;
    }

    export enum Areas {
      GOVERNANCE = 'GOVERNANCE',
      MISSION = 'MISSION',
      PEOPLE_CONTACTS_BENEFITS = 'PEOPLE_CONTACTS_BENEFITS',
      PEOPLE_DEVELOPMENT = 'PEOPLE_DEVELOPMENT',
      COMMUNITY = 'COMMUNITY',
      SUPPLIERS = 'SUPPLIERS',
      ENVIRONMENT = 'ENVIRONMENT',
      CLIENTS = 'CLIENTS',
    }

    /**
     * @property {number} globalScore
     * @property {{ label: string; score: number }[]} areasMaxScore
     * @property {{ label: string; score: number }[]} currentProfile
     * @property {{ label: string; score: number }[][]} waves
     */
    export interface ImpactProfileValue {
      globalScore: number;
      areasMaxScore: { label: string; score: number }[];
      currentProfile: { label: string; score: number }[];
      waves: { label: string; score: number }[][];
      currentProfileCertified?: boolean;
    }
  }

  export interface ConfirmationTask extends Task {
    type: TASK_TYPE.CONFIRMATION;
  }

  export interface BaseMeasureData {
    hotspots?: { id: string; co2: number }[];
  }

  export interface MeasureCO2Scope1 extends BaseMeasureData {
    d1_1: StepItem[]; // EMISSIONI DIRETTE DA FONTI STAZIONARIE
    d1_2: StepItem[]; // EMISSIONI DIRETTE DA FONTI MOBILI
    d1_3: StepItem[]; // EMISSIONI FUGGITIVE ANTROPOGENICHE DIRETTE
    d1_4: boolean; // EMISSIONI E RIMOZIONI DIRETTE DA LULUCF (LAND USE, LAND USE CHANGE AND FORESTRY)
    d1_5: boolean; // EMISSIONI E RIMOZIONI DIRETTE DA PROCESSI INDUSTRIALI
  }

  export interface MeasureCO2Scope2 extends BaseMeasureData {
    d2_1_1: number; // Energia elettrica acquistata da rete
    d2_1_2: number; // Energia elettrica consumata da veicoli della flotta aziendale
    d2_1_3: number; // Quota di energia elettrica rinnovabile acquistata da rete con garanzie d'origine
    d2_1_4: number; // Teleriscaldamento, teleraffrescamento e vapore
    d2_1_5: number; // Quota di teleriscaldamento, teleraffrescamento e vapore generati tramite fonti rinnovabili
  }

  export interface MeasureCO2Scope3 extends BaseMeasureData {
    d3_1: StepItem[]; // BENI E SERVIZI ACQUISTATI
    d3_2: StepItem[]; // EMISSIONI DA IMMOBILIZZAZIONI
    d3_3: StepItem[]; // EMISSIONI DA GENERAZIONE E DISTRIBUZIONE DI COMBUSTIBILE
    d3_4: StepItem[]; // EMISSIONI DA TRASPORTO E DISTRIBUZIONE DI BENI - UPSTREAM
    d3_5: StepItem[]; // RIFIUTI GENERATI DURANTE LE OPERAZIONI
    d3_6: StepItem[]; // EMISSIONI DA BUSINESS TRAVEL
    d3_7: StepItem[]; // EMISSIONI DA TRASPORTO CASA-LAVORO DIPENDENTI
    d3_8: StepItem[]; // EMISSIONI DA ASSET UPSTREAM IN LEASING
    d3_9: StepItem[]; // EMISSIONI DA TRASPORTO E DISTRIBUZIONE DI BENI - DOWNSTREAM
    d3_10: StepItem[]; // LAVORAZIONE DEL PRODOTTO VENDUTO
    d3_11: StepItem[]; // EMISSIONI DA UTILIZZO DEL PRODOTTO/SERVIZIO
    d3_12: StepItem[]; // EMISSIONI DA FINE VITA DEL PRODOTTO
    d3_13: StepItem[]; // EMISSIONI DA ASSET DOWNSTREAM IN LEASING
    d3_14: StepItem[]; // FRANCHISES
    d3_15: boolean; // INVESTIMENTI
  }

  export interface EmissionChartTask extends Task {
    type: TASK_TYPE.EMISSIONS_CHART;
  }

  export interface ImprovementActionsTask extends Task {
    type: TASK_TYPE.IMPROVEMENT_ACTIONS;
    value: {
      hotspot: string;
      title: TranslatableValue;
      options: { description: TranslatableValue; selected?: boolean; divider?: true }[];
    }[];
  }

  export interface CompensationsTask extends Task {
    type: TASK_TYPE.COMPENSATIONS;
    compensations: string[];
  }

  export interface MeasureDataTask<T extends BaseMeasureData> extends Task {
    value: T;
    config?: { params: any; hotspots_ref: { id: string; co2: number }[] };
  }

  export interface MeasureCO2Scope1DataTask extends MeasureDataTask<MeasureCO2Scope1> {
    type: TASK_TYPE.MEASURE_CO2_SCOPE_1;
  }

  export interface MeasureCO2Scope2DataTask extends MeasureDataTask<MeasureCO2Scope2> {
    type: TASK_TYPE.MEASURE_CO2_SCOPE_2;
  }

  export interface MeasureCO2Scope3DataTask extends MeasureDataTask<MeasureCO2Scope3> {
    type: TASK_TYPE.MEASURE_CO2_SCOPE_3;
  }

  export namespace MasterPlan {
    export interface MasterPlanTask extends Task {
      type: TASK_TYPE.MASTER_PLAN;
      value: MasterPlanValue | null;
    }
    export interface MasterPlanValue {
      nextSteps: {
        title: TranslatableValue;
        items: {
          title: TranslatableValue;
          description: TranslatableValue;
          nested:
            | {
                title: TranslatableValue;
                description: TranslatableValue;
              }[]
            | null;
        }[];
      };
      strenghts: {
        title: TranslatableValue;
        items: {
          title: TranslatableValue;
          description: TranslatableValue;
          nested:
            | {
                title: TranslatableValue;
                description: TranslatableValue;
              }[]
            | null;
        }[];
      };
    }
  }

  export interface TextareaTask extends Task {
    type: TASK_TYPE.TEXTAREA_TASK;
    required?: boolean;
    value: {
      items: {
        body: string;
        author: Mongo.ObjectId;
      }[];
    };
  }

  /**
   * ``` { completed: true }``` by default
   */
  export interface FileDownloadTask extends Task {
    type: TASK_TYPE.FILE_DOWNLOAD;
    value: {
      sources: Source.InternalSource[];
    };
  }
}
