import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { ApplicativeErrorDialogComponent } from '../components/applicative-error-dialog/applicative-error-dialog.component';

// opens the dialog when any handler error arise
export const errorHandlingFunction =
  (
    dialog: MatDialog,
    errorMessages: { [key: number]: { message: { it: string; en: string; pt: string }; redirectTo: string | null } }
  ) =>
  (err: HttpErrorResponse) => {
    console.error('[errorHandlingFunction]: ', err);
    const { error } = err;

    if (error && error.code && errorMessages) {
      const appError = errorMessages[error.code];

      if (appError) {
        dialog.open(ApplicativeErrorDialogComponent, { data: appError });
      } else {
        console.error();
        dialog.open(ApplicativeErrorDialogComponent, {
          data: {
            message: 'Oops, something went wrong. If the problem keeps up, get in contact with the support.',
            redirectTo: null,
          },
        });
      }

      return of(null);
    }

    throw err;
  };
