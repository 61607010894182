import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-wrapper',
  template: `
    <section class="d-flex flex-column h-100">
      <div class="flex-grow-1">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </section>
  `,
  styles: [],
})
export class FooterWrapperComponent {
  constructor() {}
}
