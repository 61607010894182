import { Task } from '@core/models/task';

export const taskTypeMap = new Map<Task.TASK_TYPE, string>([
  [Task.TASK_TYPE.CONFIRMATION, 'Task - conferma'],
  [Task.TASK_TYPE.EXTERNAL_ACTIVITY, 'Task - attività esterna'],
  [Task.TASK_TYPE.FILE_DOWNLOAD, 'Task - download file'],
  [Task.TASK_TYPE.FILLABLE_LIST, 'Task - lista compilabile'],
  [Task.TASK_TYPE.IMPACT_PROFILE, 'Task - profilo di impatto'],
  [Task.TASK_TYPE.INFO, 'Task - informazioni'],
  [Task.TASK_TYPE.INSTRUCTION, 'Task - istruzioni'],
  [Task.TASK_TYPE.MASTER_PLAN, 'Task - piano master'],
  [Task.TASK_TYPE.SYNC_WITH_NATIVA, 'Task - sincronizzazione con nativa'],
  [Task.TASK_TYPE.PRESENTATION, 'Task - presentazione'],
  [Task.TASK_TYPE.QUIZ, 'Task - quiz'],
  [Task.TASK_TYPE.TEXTAREA_TASK, 'Task - area di testo'],
  [Task.TASK_TYPE.VIDEO, 'Task - video'],
]);
